import React, { useState } from 'react';
import { Card, Typography, Tabs } from 'antd';
import AnalyticsTab from './AnalyticsTab';
import ManagementTab from './ManagementTab';

const { Title } = Typography;
const { TabPane } = Tabs;

interface AdminDashboardProps {
  isAuth: boolean;
  usersSharedState: any;
  setUsersSharedState: any;
  showLoginModal: () => void;
}

const AdminDashboard: React.FC<AdminDashboardProps> = ({
  isAuth,
  usersSharedState,
  setUsersSharedState,
  showLoginModal,
}) => {
  const [activeTab, setActiveTab] = useState<string>('management');

  if (!isAuth) {
    return null;
  }

  return (
    <div>
      <Title level={2}>Admin Dashboard</Title>
      <Tabs defaultActiveKey="management" onChange={(key) => setActiveTab(key)}>
        <TabPane tab="Management" key="management">
          <ManagementTab usersSharedState={usersSharedState} />
        </TabPane>
        <TabPane tab="Analytics" key="analytics">
          {activeTab === 'analytics' && (
            <AnalyticsTab usersSharedState={usersSharedState} />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AdminDashboard;