//ProofreadModal.tsx
import React from 'react';
import { Modal, Button, Select, Checkbox, Tooltip, Input, Tag } from 'antd';
import { QuestionCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';


interface ProofreadModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onProofread: () => void;
  selectedChapter: any;
  proofreadVersion: string;
  setProofreadVersion: (value: string) => void;
  isProofreadChecked: boolean;
  setIsProofreadChecked: (value: boolean) => void;
  isEditingNotesChecked: boolean;
  setIsEditingNotesChecked: (value: boolean) => void;
  quotationStyle: string;
  setQuotationStyle: (value: string) => void;
  proofreadAdditionalInstructions: string;
  setProofreadAdditionalInstructions: (value: string) => void;  
  selectedAIModel: string;
  setSelectedAIModel: (value: string) => void;
  creditBalance: { credit_balance: number } | null;
  calculateAdjustedCost: () => number;
  userHasEnoughCredits: boolean;
  hasUnproofreadChapters: boolean;
  hasUnproofreadNotesChapters: boolean;
  canChapterBeProofread: boolean;
  hasChapterBeenProofread: boolean;
  hasChapterBeenEditNotes: boolean;
  hasUntranslatedChaptersForProofread: boolean;
  book: any;
  formatLangDisplayText: (pair: string) => string;
  bookLanguage: string;
}

const ProofreadModal: React.FC<ProofreadModalProps> = ({
  isVisible,
  onCancel,
  onProofread,
  selectedChapter,
  proofreadVersion,
  setProofreadVersion,
  isProofreadChecked,
  setIsProofreadChecked,
  isEditingNotesChecked,
  setIsEditingNotesChecked,
  quotationStyle,
  setQuotationStyle,
  proofreadAdditionalInstructions,
  setProofreadAdditionalInstructions,  
  selectedAIModel,
  setSelectedAIModel,
  creditBalance,
  calculateAdjustedCost,
  userHasEnoughCredits,
  hasUnproofreadChapters,
  hasUnproofreadNotesChapters,
  canChapterBeProofread,
  hasChapterBeenProofread,
  hasChapterBeenEditNotes,
  hasUntranslatedChaptersForProofread,
  book,
  formatLangDisplayText,
  bookLanguage
}) => {
  return (
    <Modal
      title={selectedChapter ? "Proofread Chapter" : "Proofread Book"}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>Cancel</Button>,
        <Button 
          key="proofread" 
          type="primary" 
          onClick={onProofread} 
          disabled={
            (!hasUnproofreadChapters && isProofreadChecked && !selectedChapter) ||
            (!hasUnproofreadNotesChapters && isEditingNotesChecked && !selectedChapter) ||
            !userHasEnoughCredits ||
            (!isProofreadChecked && !isEditingNotesChecked) ||
            calculateAdjustedCost() === 0 ||
            (selectedChapter && !canChapterBeProofread) ||
            (!selectedChapter && hasUntranslatedChaptersForProofread)
          }
        >
          Proofread
        </Button>
      ]}
    >
      <div>
        ScribeShadow can offer editorial suggestions and proofreading to enhance your writing or your translations.
        <div style={{ marginTop: '5px', marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Version to Proofread:</label>
          <Select
            value={proofreadVersion}
            onChange={value => {
              setProofreadVersion(value);
              if (value.includes('-German')) {
                setQuotationStyle('german guillemets');
              }
            }}
            style={{ width: '100%' }}
          >
            <Select.Option value="Original">Original</Select.Option>
            {book.translation_pairs && book.translation_pairs.map((pair: string) => (
              <Select.Option key={pair} value={pair}>{formatLangDisplayText(pair)}</Select.Option>
            ))}
          </Select>
        </div>

        <div>
          <Checkbox checked={isProofreadChecked} onChange={e => setIsProofreadChecked(e.target.checked)} disabled={selectedChapter ? !canChapterBeProofread : undefined}>
            {selectedChapter ? "Proofread this Chapter" : "Proofread Entire Book"}
          </Checkbox>
          <Tooltip title="Select this option to proofread the chapter or book, improving its language and readability.">
            <QuestionCircleOutlined style={{ color: 'blue', marginLeft: 8 }} />
          </Tooltip>
        </div>
        <div>
          <Checkbox checked={isEditingNotesChecked} onChange={e => setIsEditingNotesChecked(e.target.checked)} disabled={selectedChapter ? !canChapterBeProofread : undefined}>
            {selectedChapter ? "Get Editing Notes for this Chapter" : "Get Editing Notes for Entire Book"}
          </Checkbox>
          <Tooltip title="Select this option if you want to receive editing notes, providing insights and suggestions for further improvements.">
            <QuestionCircleOutlined style={{ color: 'blue', marginLeft: 8 }} />
          </Tooltip>
        </div>

        {(proofreadVersion.includes('-German') || (proofreadVersion === 'Original' && bookLanguage === 'German')) && (
          <div style={{ marginBottom: '10px', marginTop: '10px' }}>
            <label style={{ marginRight: '10px' }}>Quotation Style:</label>
            <Select
              value={quotationStyle}
              onChange={value => setQuotationStyle(value)}
              style={{ width: '100%' }}
            >
              <Select.Option value="german quotation marks">German Quotation Marks („Dialog Text")</Select.Option>
              <Select.Option value="german guillemets">Guillemets (»Dialog Text«)</Select.Option>
            </Select>
          </div>
        )}

        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>AI Model:</label>
          <Select
            value={selectedAIModel}
            onChange={(value) => setSelectedAIModel(value)}
            style={{ width: '100%' }}
          >
            <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>
            <Select.Option value="Claude">
			Claude 3.5 <Tag color="geekblue" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Recommended <CheckCircleOutlined /></Tag>
			</Select.Option>
          </Select>
        </div>
{isProofreadChecked && (
  <div style={{ marginBottom: '10px', marginTop: '10px' }}>
    <label>Additional Instructions (optional):</label>
    <Tooltip title="You can provide specific instructions or preferences for the proofreading process here.">
      <QuestionCircleOutlined style={{ color: 'blue', marginLeft: 8 }} />
    </Tooltip>		  
    <Input.TextArea
      value={proofreadAdditionalInstructions}
      onChange={(e) => setProofreadAdditionalInstructions(e.target.value)}
      placeholder="Enter any additional instructions for proofreading"
      rows={4}
    />
  </div>
)}
        <div>
          <label>Credit Balance: </label>
          <span>{creditBalance ? creditBalance.credit_balance : 'Loading...'}</span>
        </div>
        <div>
          <label>Credit Cost: </label>
          <span>{calculateAdjustedCost()}</span>
          <Tooltip title="This value may differ from the word count of your document due to the inclusion of hidden tags and metadata necessary to maintain formatting integrity.">
            <QuestionCircleOutlined style={{ color: 'blue', marginLeft: 8 }} />
          </Tooltip>	
        </div>
        
        {/* Messages */}
        {selectedChapter && isProofreadChecked && hasChapterBeenProofread && <div style={{ color: '#CC5500' }}>Warning: This chapter already has a proofread version and will be overwritten.</div>}
        {selectedChapter && isEditingNotesChecked && hasChapterBeenEditNotes && <div style={{ color: '#CC5500' }}>Warning: This chapter already has editing notes.</div>}
        {!hasUnproofreadChapters && isProofreadChecked && !selectedChapter && <div style={{ color: 'red' }}>All chapters have been proofread.</div>}
        {!hasUnproofreadNotesChapters && isEditingNotesChecked && !selectedChapter && <div style={{ color: 'red' }}>All chapters have editing notes.</div>}
        {!selectedChapter && isProofreadChecked && hasUnproofreadChapters && (<p>ScribeShadow will only proofread chapters that have not yet been proofread.</p>)}	  
        {!userHasEnoughCredits && <div style={{ color: 'red' }}>You do not have enough credits for this proofreading.</div>}
        {!selectedChapter && isEditingNotesChecked && hasUnproofreadChapters && (<p>ScribeShadow will only provide editing notes for chapters that do not yet have editing notes.</p>)}	  
        {!selectedChapter && proofreadVersion && hasUntranslatedChaptersForProofread && <div style={{ color: 'red' }}>Unable to proofread this language. Not all chapters have been translated to the target language.</div>}
        {selectedChapter && proofreadVersion && !canChapterBeProofread && <div style={{ color: 'red' }}>Unable to proofread. This chapter has not been translated to the specified language.</div>}
		{!proofreadVersion && <div style={{ color: 'red' }}>Please select a version to proofread to proceed.</div>}
      </div>
    </Modal>
  );
};

export default ProofreadModal;