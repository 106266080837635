//ManagementTab.tsx
import React, { useState } from 'react';
import { Card, Input, Button, message, Table, Modal, Spin, Select, InputNumber } from 'antd';
import axios from 'axios';

const { Search } = Input;

interface ManagementTabProps {
  usersSharedState: any;
}

interface Transaction {
  timestamp: string;
  description: string;
  book: string;
  chapter: string;
  language: string;
  credits: number;
  type: 'deduction' | 'addition';
}

interface UserInfo {
  userId: string;
  email: string;
  stripeCustomerID: string;
  stripeProductID: string;
  stripeSubscriptionID: string;
  affiliateCode: string;
  affiliateCodeID: string;
  betaAccess: boolean;
  monthlyCredits: number;
  foreverCredits: number;
  planName: string;
}

const ManagementTab: React.FC<ManagementTabProps> = ({ usersSharedState }) => {
  const [bookGuidInput, setBookGuidInput] = useState('');
  const [email, setEmail] = useState('');
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [books, setBooks] = useState([]);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isBookModalVisible, setIsBookModalVisible] = useState(false);
  const [isTransactionsModalVisible, setIsTransactionsModalVisible] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [affiliateCode, setAffiliateCode] = useState('');
  const [affiliateId, setAffiliateId] = useState(''); 
  const [isAffiliateModalVisible, setIsAffiliateModalVisible] = useState(false);  
  const [isAddCreditsModalVisible, setIsAddCreditsModalVisible] = useState(false);
  const [creditType, setCreditType] = useState<'normal' | 'forever'>('normal');
  const [creditsToAdd, setCreditsToAdd] = useState<number | null>(null);
  const [creditReason, setCreditReason] = useState('');
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

const columns = [
  { title: 'Title', dataIndex: 'title', key: 'title' },
  { title: 'Language', dataIndex: 'language', key: 'language' },
  { title: 'Upload Type', dataIndex: 'uploadType', key: 'uploadType' },
  { title: 'Book ID', dataIndex: 'bookGuid', key: 'bookGuid' },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: any) => (
      <Button onClick={() => handleCopyBookToAdmin(record.bookGuid)}>
        Copy to Admin
      </Button>
    ),
  },
];

  const handleCopyToAdmin = () => {
    const trimmedBookGuid = bookGuidInput.trim();

    if (!trimmedBookGuid) {
      message.error('Please enter a Book GUID.');
      return;
    }

    setIsGlobalLoading(true);
    const userId = usersSharedState.userID;
    axios.post('http://localhost:8000/copy-to-admin', { user_id: userId, book_guid: bookGuidInput })
      .then(response => {
        message.success('Book copied successfully!');
        setBookGuidInput('');
      })
      .catch(error => {
        console.error('Error copying book:', error);
        message.error('Error occurred while copying the book.');
      })
      .finally(() => setIsGlobalLoading(false));
  };

const fetchUserInfo = async () => {
  setIsGlobalLoading(true);
  try {
    const currentUserId = usersSharedState.userID; // Assuming this is how you get the current user's ID
    const response = await axios.get<UserInfo>(`http://localhost:8000/user-info/${email}`, {
      params: { current_user_id: currentUserId }
    });
    setUserInfo(response.data);
    setIsUserModalVisible(true);
  } catch (error) {
    console.error('Failed to fetch user info:', error);
    message.error('Failed to fetch user info');
  } finally {
    setIsGlobalLoading(false);
  }
};

  const fetchBooks = async () => {
    setIsGlobalLoading(true);
    try {
      const response = await axios.get(`http://localhost:8000/uploadedbooks/admin/${email}`);
      setBooks(response.data.books);
      setIsBookModalVisible(true);
    } catch (error) {
      console.error('Failed to fetch books:', error);
      message.error('Failed to fetch books');
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const fetchTransactions = async () => {
    setIsGlobalLoading(true);
    try {
      const response = await axios.get<Transaction[]>('http://localhost:8000/credit-history', {
        params: { userID: userInfo?.userId },
      });
      setTransactions(response.data);
      setIsTransactionsModalVisible(true);
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
      message.error('Failed to fetch transactions');
    } finally {
      setIsGlobalLoading(false);
    }
  };

const exportToCSV = () => {
  if (transactions.length === 0) {
    message.error('No transactions to export');
    return;
  }

const escapeField = (field: any): string => {
  if (field === undefined || field === null) {
    return '""';
  }
  // Convert to string if it's not already
  const stringField = typeof field === 'string' ? field : String(field);
  // Remove any double quotes from the field
  const escapedField = stringField.replace(/"/g, '');
  // Enclose the field in double quotes
  return `"${escapedField}"`;
};

  const csvContent = [
    ['Date', 'Description', 'Book', 'Chapter', 'Language', 'Credits', 'Type'],
    ...transactions.map(t => [
      escapeField(new Date(t.timestamp).toLocaleString()),
      escapeField(t.description),
      escapeField(t.book),
      escapeField(t.chapter),
      escapeField(t.language),
      escapeField((t.type === 'deduction' ? -t.credits : t.credits).toString()),
      escapeField(t.type)
    ])
  ].map(e => e.join(',')).join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'transactions.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};


const handleSetAffiliateInfo = () => {
  setAffiliateCode(userInfo?.affiliateCode || '');
  setAffiliateId(userInfo?.affiliateCodeID || '');
  setIsAffiliateModalVisible(true);
};

const submitAffiliateInfo = async () => {
  setIsGlobalLoading(true);
  try {
    const currentUserId = usersSharedState.userID;
    await axios.post('http://localhost:8000/set-affiliate-info', {
      current_user_id: currentUserId,
      user_id: userInfo?.userId,
      affiliate_code: affiliateCode,
      affiliate_id: affiliateId
    });
    message.success('Affiliate information updated successfully');
    setIsAffiliateModalVisible(false);
    // Refresh user info
    await fetchUserInfo();
  } catch (error) {
    console.error('Failed to set affiliate info:', error);
    message.error('Failed to set affiliate info');
  } finally {
    setIsGlobalLoading(false);
  }
};

const handleCopyBookToAdmin = (bookGuid: string) => {
  setIsGlobalLoading(true);
  const userId = usersSharedState.userID;
  axios.post('http://localhost:8000/copy-to-admin', { user_id: userId, book_guid: bookGuid })
    .then(response => {
      message.success('Book copied successfully!');
    })
    .catch(error => {
      console.error('Error copying book:', error);
      message.error('Error occurred while copying the book.');
    })
    .finally(() => setIsGlobalLoading(false));
};


  const handleAddCredits = () => {
    setIsAddCreditsModalVisible(true);
  };

  const submitAddCredits = async () => {
    if (!creditType || !creditsToAdd || !creditReason) {
      message.error('Please fill in all fields');
      return;
    }

    if (creditsToAdd <= 0) {
      message.error('Credits to add must be a positive number');
      return;
    }

    setIsGlobalLoading(true);
    try {
      const currentUserId = usersSharedState.userID;
      await axios.post('http://localhost:8000/add-credits', {
        current_user_id: currentUserId,
        user_id: userInfo?.userId,
        credit_type: creditType,
        credits: creditsToAdd,
        reason: creditReason
      });
      message.success('Credits added successfully');
      setIsAddCreditsModalVisible(false);
      // Refresh user info
      await fetchUserInfo();
    } catch (error) {
      console.error('Failed to add credits:', error);
      message.error('Failed to add credits');
    } finally {
      setIsGlobalLoading(false);
    }
  };

  return (

    <div>
      <Card title="Lookup User">
          <Input
            placeholder="Enter User Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: 200, marginRight: 10 }}
          />
          <Button type="primary" onClick={fetchUserInfo}>Lookup User</Button>
      </Card>

	<Spin spinning={isGlobalLoading} size="large">
      <Card title="Copy Book to Admin">
          <Search
            placeholder="Enter Book GUID"
            enterButton="Copy to Admin"
            size="large"
            value={bookGuidInput}
            onChange={(e) => setBookGuidInput(e.target.value)}
            onSearch={handleCopyToAdmin}
          />
      </Card>

      <Modal
        title="User Information"
        visible={isUserModalVisible}
        onOk={() => setIsUserModalVisible(false)}
        onCancel={() => setIsUserModalVisible(false)}
		footer={[
		<div key="buttons" style={{ display: 'flex', justifyContent: 'center' }}>
			<Button key="viewTransactions" onClick={fetchTransactions} style={{ margin: '0 5px' }}>
			View Transactions
			</Button>
			<Button key="viewBooks" onClick={fetchBooks} style={{ margin: '0 5px' }}>
			View Books
			</Button>
			<Button key="setAffiliateInfo" onClick={handleSetAffiliateInfo} style={{ margin: '0 5px' }}>
			Set Affiliate Info
			</Button>
            <Button key="addCredits" onClick={handleAddCredits} style={{ margin: '0 5px' }}>
              Add Credits
            </Button>			
		</div>
		]}
      >
	  <Spin spinning={isGlobalLoading} size="large">
        {userInfo && (
          <div>
            <p><strong>User ID:</strong> {userInfo.userId}</p>
            <p><strong>Email:</strong> {userInfo.email}</p>
            <p><strong>Plan Name:</strong> {userInfo.planName}</p>
			<p><strong>Stripe Customer ID:</strong> {userInfo.stripeCustomerID}</p>
            <p><strong>Stripe Product ID:</strong> {userInfo.stripeProductID}</p>
            <p><strong>Stripe Subscription ID:</strong> {userInfo.stripeSubscriptionID}</p>
            <p><strong>Affiliate Code:</strong> {userInfo.affiliateCode}</p>
            <p><strong>Affiliate Code ID:</strong> {userInfo.affiliateCodeID}</p>
            <p><strong>Beta Access:</strong> {userInfo.betaAccess ? 'Yes' : 'No'}</p>
            <p><strong>Monthly Credits:</strong> {userInfo.monthlyCredits}</p>
            <p><strong>Forever Credits:</strong> {userInfo.foreverCredits}</p>
          </div>
        )}
		</Spin>
      </Modal>
	  
      <Modal
        title="User Books"
        visible={isBookModalVisible}
        onOk={() => setIsBookModalVisible(false)}
        onCancel={() => setIsBookModalVisible(false)}
        width={1200}
		footer={[
          <Button key="close" onClick={() => setIsBookModalVisible(false)}>
            Close
          </Button>		
		]}
      >
	  <Spin spinning={isGlobalLoading} size="large">
          <Table dataSource={books} columns={columns} rowKey="bookID" />
	  </Spin>  
      </Modal>

      <Modal
        title="User Transactions"
        visible={isTransactionsModalVisible}
        onOk={() => setIsTransactionsModalVisible(false)}
        onCancel={() => setIsTransactionsModalVisible(false)}
        width={1000}
        footer={[
          <Button key="export" onClick={exportToCSV}>
            Export to CSV
          </Button>,
          <Button key="close" onClick={() => setIsTransactionsModalVisible(false)}>
            Close
          </Button>
        ]}
      >
        <Table
          dataSource={transactions}
          columns={[
            { title: 'Date', dataIndex: 'timestamp', key: 'timestamp', render: (text: string) => new Date(text).toLocaleString() },
            { title: 'Description', dataIndex: 'description', key: 'description' },
            { title: 'Book', dataIndex: 'book', key: 'book' },
            { title: 'Chapter', dataIndex: 'chapter', key: 'chapter' },
            { title: 'Language', dataIndex: 'language', key: 'language' },
            { 
              title: 'Credits', 
              dataIndex: 'credits', 
              key: 'credits',
              render: (text: number, record: any) => (
                <span style={{ color: record.type === 'deduction' ? 'red' : 'green' }}>
                  {record.type === 'deduction' ? '-' : '+'}
                  {text}
                </span>
              ),
            },
          ]}
          rowKey="timestamp"
        />
      </Modal>
	  
<Modal
  title="Set Affiliate Information"
  visible={isAffiliateModalVisible}
  onOk={submitAffiliateInfo}
  onCancel={() => setIsAffiliateModalVisible(false)}
>
<Spin spinning={isGlobalLoading} size="large">
  <div style={{ marginBottom: 16 }}>
    <label htmlFor="affiliateCode">Affiliate Code:</label>
    <Input
      id="affiliateCode"
      value={affiliateCode}
      onChange={(e) => setAffiliateCode(e.target.value)}
    />
  </div>
  <div>
    <label htmlFor="affiliateId">Affiliate ID:</label>
    <Input
      id="affiliateId"
      value={affiliateId}
      onChange={(e) => setAffiliateId(e.target.value)}
    />
  </div>
  </Spin>
</Modal>  

      <Modal
        title="Add Credits"
        visible={isAddCreditsModalVisible}
        onOk={submitAddCredits}
        onCancel={() => setIsAddCreditsModalVisible(false)}
      >
        <Spin spinning={isGlobalLoading} size="large">
          <div style={{ marginBottom: 16 }}>
            <p><strong>Current Credits:</strong> {userInfo?.monthlyCredits}</p>
            <p><strong>Current Forever Credits:</strong> {userInfo?.foreverCredits}</p>
          </div>
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="creditType">Credit Type:</label>
            <Select
              id="creditType"
              value={creditType}
              onChange={(value) => setCreditType(value)}
              style={{ width: '100%' }}
            >
              <Select.Option value="normal">Normal</Select.Option>
              <Select.Option value="forever">Forever</Select.Option>
            </Select>
          </div>
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="creditsToAdd">Number of Credits to Add:</label>
            <InputNumber
              id="creditsToAdd"
              min={1}
              value={creditsToAdd}
              onChange={(value) => setCreditsToAdd(value)}
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label htmlFor="creditReason">Credit Reason:</label>
            <Input
              id="creditReason"
              value={creditReason}
              onChange={(e) => setCreditReason(e.target.value)}
            />
          </div>
        </Spin>
      </Modal>
	  
	  </Spin>
    </div>

  );
};

export default ManagementTab;