// AlwaysTranslateModal.tsx
import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Tag, Tooltip, message, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, QuestionCircleOutlined } from '@ant-design/icons';

interface AlwaysTranslateModalProps {
  isVisible: boolean;
  onClose: () => void;
  currentLanguagePair: string;
  alwaysTranslateWords: { [key: string]: string[] };
  setAlwaysTranslateWords: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  bookGuid: string;
}

const AlwaysTranslateModal: React.FC<AlwaysTranslateModalProps> = ({
  isVisible,
  onClose,
  currentLanguagePair,
  alwaysTranslateWords,
  setAlwaysTranslateWords,
  bookGuid,
}) => {
  const [newSourceWord, setNewSourceWord] = useState('');
  const [newTargetWord, setNewTargetWord] = useState('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  //const [editingWord, setEditingWord] = useState<string | null>(null);
  const [editedSourceWord, setEditedSourceWord] = useState('');
  const [editedTargetWord, setEditedTargetWord] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Reset states when modal is opened/closed
  useEffect(() => {
    if (isVisible) {
      setNewSourceWord('');
      setNewTargetWord('');
      setEditingIndex(null);
      setEditedSourceWord('');
      setEditedTargetWord('');
    }
  }, [isVisible]);

   const updateAPI = async (words: string[]): Promise<boolean> => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://askpoeai-nswdzfky7q-uc.a.run.app/update-always-translate/${bookGuid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          language_pair: currentLanguagePair,
          words: words,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update API');
      }
      return true;
    } catch (error) {
      console.error('Error updating API:', error);
      message.error('Failed to update. Please try again.');
      return false;
    } finally {
      setIsLoading(false);
    }
  };


const isValidInput = (input: string): boolean => {
  // Allow Unicode letters, numbers, spaces, hyphens, and apostrophes
  return /^[\p{L}\p{N}\s\-']+$/u.test(input);
};

const handleAddWord = async () => {
  if (newSourceWord.trim() && newTargetWord.trim()) {
    if (isValidInput(newSourceWord.trim()) && isValidInput(newTargetWord.trim())) {
      const newPair = `${newSourceWord.trim()} | ${newTargetWord.trim()}`;
      const updatedWords = [...(alwaysTranslateWords[currentLanguagePair] || []), newPair];
      const success = await updateAPI(updatedWords);
      if (success) {
        setAlwaysTranslateWords(prevState => ({
          ...prevState,
          [currentLanguagePair]: updatedWords
        }));
        setNewSourceWord('');
        setNewTargetWord('');
      }
    } else {
      message.error('Please enter valid words or phrases (only letters, numbers, spaces, hyphens, and apostrophes are allowed).');
    }
  } else {
    message.error('Please enter both source and target words or phrases.');
  }
};

 const handleSaveEdit = async () => {
  if (editingIndex !== null && editedSourceWord.trim() && editedTargetWord.trim()) {
    if (isValidInput(editedSourceWord.trim()) && isValidInput(editedTargetWord.trim())) {
      const updatedWords = [...(alwaysTranslateWords[currentLanguagePair] || [])];
      updatedWords[editingIndex] = `${editedSourceWord.trim()} | ${editedTargetWord.trim()}`;
      const success = await updateAPI(updatedWords);
      if (success) {
        setAlwaysTranslateWords(prevState => ({
          ...prevState,
          [currentLanguagePair]: updatedWords
        }));
        setEditingIndex(null);
      }
    } else {
      message.error('Please enter valid words or phrases (only letters, numbers, spaces, and hyphens are allowed).');
    }
  } else {
    message.error('Please enter both source and target words or phrases.');
  }
};

const handleRemoveWord = async (index: number) => {
  const updatedWords = (alwaysTranslateWords[currentLanguagePair] || []).filter((_, i) => i !== index);
  const success = await updateAPI(updatedWords);
  if (success) {
    setAlwaysTranslateWords(prevState => ({
      ...prevState,
      [currentLanguagePair]: updatedWords
    }));
  }
};

const handleEditWord = (index: number) => {
  const pair = alwaysTranslateWords[currentLanguagePair][index];
  const [sourceWord, targetWord] = pair.split(' | ');
  setEditingIndex(index);
  setEditedSourceWord(sourceWord);
  setEditedTargetWord(targetWord);
};

  const handleModalClose = () => {
    setEditingIndex(null);
    onClose();
  };


    return (
    <Modal
      title={
        <span>
          Always Translate for {currentLanguagePair}
          <Tooltip title="The AI will always translate the source text to the specified target text">
            <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
          </Tooltip>
        </span>
      }
      visible={isVisible}
      onCancel={handleModalClose}
      footer={[
        <Button key="close" onClick={handleModalClose}>
          Close
        </Button>
      ]}
    >
      <Spin spinning={isLoading}>
        <strong>Translate</strong>
        <Input
          value={newSourceWord}
          onChange={(e) => setNewSourceWord(e.target.value)}
          placeholder="Enter source word or phrase"
          style={{ marginBottom: '10px' }}
        />
        <strong>To</strong>
        <Input
          value={newTargetWord}
          onChange={(e) => setNewTargetWord(e.target.value)}
          placeholder="Enter target word or phrase"
          style={{ marginBottom: '10px' }}
        />
        <Button onClick={handleAddWord} style={{ marginBottom: '20px' }}>
          Add Word/Phrase Pair
        </Button>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <h4>Current Always Translate Pairs:</h4>
          {currentLanguagePair && (alwaysTranslateWords[currentLanguagePair] ?? []).map((pair, index) => {
            const [sourceWord, targetWord] = pair.split(' | ');
            return (
              <Tag key={index} style={{ marginBottom: '5px', fontSize: '16px' }}>
                {editingIndex === index ? (
                  <>
                    <Input
                      value={editedSourceWord}
                      onChange={(e) => setEditedSourceWord(e.target.value)}
                      style={{ width: '120px', marginRight: '5px' }}
                    />
                    <Input
                      value={editedTargetWord}
                      onChange={(e) => setEditedTargetWord(e.target.value)}
                      style={{ width: '120px' }}
                    />
                  </>
                ) : (
                  `${sourceWord} -> ${targetWord}`
                )}
                <Tooltip title={editingIndex === index ? "Save" : "Edit"}>
                  {editingIndex === index ? (
                    <SaveOutlined
                      onClick={handleSaveEdit}
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                    />
                  ) : (
                    <EditOutlined
                      onClick={() => handleEditWord(index)}
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                    />
                  )}
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteOutlined
                    onClick={() => handleRemoveWord(index)}
                    style={{ marginLeft: '5px', cursor: 'pointer', color: 'red' }}
                  />
                </Tooltip>
              </Tag>
            );
          })}
        </div>
      </Spin>
    </Modal>
  );
};

export default AlwaysTranslateModal;