//SignInModalComponent.tsx
import React from 'react';
import { Modal, Form, Input, Divider, Button, Spin } from 'antd';

interface Props {
  isLoginModalVisible: boolean;
  handleLoginCancel: () => void;
  onFormSubmit: (values: { username: string; password: string; actionType: string; }) => void;
  signInWithGoogle: () => void;
  error: string | null;  
  resetPassword: (email: string) => void;
  showResetModal: () => void;
  setIsResetModalVisible: (value: boolean) => void; 
  loading: boolean;
  message: string | null;
}


const SignInModalComponent: React.FC<Props> = ({ isLoginModalVisible, handleLoginCancel, onFormSubmit, signInWithGoogle, error,setIsResetModalVisible, loading, message }) => {

const showResetModal = () => {
  setIsResetModalVisible(true);
};

const [form] = Form.useForm();

  const validateEmail = (_: any, value: string) => {
    if (!value) {
      return Promise.reject('Please input your email!');
    }

    // Sanitize the email
    const sanitizedEmail = value.trim().toLowerCase();

    // Basic email regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(sanitizedEmail)) {
      return Promise.reject('Please enter a valid email address!');
    }

    // Update the form field with the sanitized email
    form.setFieldsValue({ username: sanitizedEmail });

    return Promise.resolve();
  };

  return (
  <Modal
    title="Sign In / Sign Up"
    visible={isLoginModalVisible}
    onCancel={handleLoginCancel}
    footer={null} // Remove default footer
    centered
  >
    <p style={{ textAlign: 'center' }}><strong>New Users receive 5000 free credits when they sign up!</strong></p>

    <Form form={form} layout="vertical" onFinish={onFormSubmit}>

      <Form.Item
        label="Email"
        name="username"
        rules={[
          { validator: validateEmail },
        ]}
        help={error}  // Display error message
        validateStatus={error ? "error" : ""}  // Show error style
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
	  <p style={{ textAlign: 'center' }}>{message}</p>
		<Form.Item style={{ display: 'none' }} name="actionType">
        <Input />
      </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
        <Button 
          type="primary" 
          htmlType="submit"  
		  loading={loading}
          onClick={() => {
            const actionType = 'login';  // Set the action type
            form.setFieldsValue({ actionType });  // Update the hidden field value
          }} 
          style={{ marginRight: '10px' }}
        >
          Sign In
        </Button>
        <Button 
          htmlType="submit" 
		  loading={loading}
          onClick={() => {
            const actionType = 'register';  // Set the action type
            form.setFieldsValue({ actionType });  // Update the hidden field value
          }}
        >
          Sign Up
        </Button>
      </Form.Item>
    </Form>
	<p style={{ textAlign: 'center' }}>
	<button onClick={() => showResetModal()} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
		Forgot password?
	</button>
	</p>
    <Divider>Or</Divider>

    <p style={{ textAlign: 'center' }}>
      <button onClick={signInWithGoogle} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
        <img src="/btn_google_signin_light_focus_web.png" alt="Google logo" />
      </button>
    </p>
  </Modal>
);
};
export default SignInModalComponent;
