// LocationsTab.tsx
import React, { ChangeEvent, useState, useEffect } from 'react';
import { Input, Select, Tooltip, Button, Space, Modal, Spin, Checkbox, List, Row, Col, Divider, Card, Popconfirm, message, Dropdown, Menu } from 'antd';
import {MoreOutlined, InfoCircleOutlined } from '@ant-design/icons'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from "firebase/auth";
import { getFirestore, doc, updateDoc, Timestamp, getDoc  } from "firebase/firestore"; 
import './GeneratingGifStyle.css';
import styled from 'styled-components';
import { Typography, Tag } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';

import { SharedState, UsersSharedState, TabProps, CustomAxiosError, Location, MainCharacter, SupportingCharacter, Character } from './types';
import { formatCharacters, formatLocations } from './formatFunctions'; 

const { TextArea } = Input;
const { Option } = Select;

const StyledList = styled(List)`
  width: 95%;
  
  .ant-list-item {
    cursor: pointer;
  }
  
  .ant-list-item:hover {
    background-color: #f0f0f0;
  }
  
  .ant-list-item-selected {
    background-color: #e6f7ff;
  }
`;


const LocationsTab: React.FC<TabProps> = ({ sharedState, setSharedState, isAuth, user, usersSharedState, setUsersSharedState }) => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmLocationsModalVisible, setIsConfirmLocationsModalVisible] = useState(false);
  const [shouldSkipLocationsConfirm, setShouldSkipLocationsConfirm] = useState(false);

  const db = getFirestore();
  
  const [editIndex, setEditIndex] = useState(null);
     
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<number | null>(null);


  const [currentLocationName, setCurrentLocationName] = useState('');
  const [currentLocationDescription, setCurrentLocationDescription] = useState('');
  const [visibleMainDropdownIndex, setVisibleMainDropdownIndex] = useState(-1);
  
  const [initialLocations, setInitialLocations] = useState('');
  
  const [costState, setCostState] = useState<Record<string, any>>({}); 
  const [act1, setAct1] = useState('');
  const [act2, setAct2] = useState('');
  const [act3, setAct3] = useState('');
  const [bookGenre, setBookGenre] = useState("")
  const [bookTropes, setBookTropes] = useState("")  
  const [apiModel, setApiModel] = useState("Claude")

useEffect(() => {
  setSelectedLocation(null);
  setLocations([]);
  setCurrentLocationName('');
  setCurrentLocationDescription('');
}, [sharedState.bookguid]);

useEffect(() => {
  if (!isAuth) {
    // User has logged out, so clear the state
    setLocations([]);
	setSelectedLocation(null);
	setCurrentLocationName('');
	setCurrentLocationDescription('');
  }
}, [isAuth]);

  
  ///Load Book data
  useEffect(() => {
    const fetchData = async () => {
      if (!sharedState.bookguid) return;  // Exit if no bookguid is available
	  console.log("Fetching book data (location)")
      setIsLoading(true);
      try {
        const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/api/books/highlevel/${sharedState.bookguid}`);
        const data = response.data;
        
        let parsedLocations = [];
        try {
            parsedLocations = JSON.parse(data.locations || '[]');
            console.log("Parsed locations:", parsedLocations);  // Log parsed data
            if (parsedLocations && parsedLocations.locations) {
                setLocations(parsedLocations.locations);  // Use the array inside the 'locations' property
            } else {
                setLocations([]);  // Default to an empty array if the structure isn't as expected
            }
        } catch (error) {
            console.error("Failed to parse locations data: ", error);
            setLocations([]);  // Default to an empty array in case of parsing error
        }
			
		setApiModel(data.model);   
		setBookGenre(data.genre);		
		setBookTropes(data.tropes);
		setAct1(data.act1);
		setAct2(data.act2);
		setAct3(data.act3);		  
		  
      } catch (error: any) {
        console.error("Failed to fetch book details: ", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sharedState.bookguid]);  

  useEffect(() => {
    const fetchGenerationCosts = async () => {
      setIsLoading(true);
      try {
        const generationCostsResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get_generation_cost/');
        const costsData: Record<string, any> = generationCostsResponse.data.creditCosts;
        const generationTypes = [
          'BasicPlot', 'Tropes', 'WritingStyle', 'MainCharacters', 
          'SupportingCharacters', 'Locations', 'Outline', 
          'Title', 'Blurb', 'Novel', 'ChapterSummary','CharacterName','CharacterDetails',
          'LocationName','LocationDetails'
        ];

        let newState: Record<string, any> = {}; 
        for (let genType of generationTypes) {
          const key = `${genType}_${apiModel}`;
          newState[`gen${genType}Cost`] = costsData[key];
        }

        // Updating the new costState with the fetched costs
        setCostState(newState);
        
		console.log("Cost State genBasicPlotCost: "+costState.genBasicPlotCost);
		
      } catch (error) {
        console.error("Failed to fetch generation costs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (apiModel) {  // Ensuring model is available before fetching
      fetchGenerationCosts();
    }
  }, [sharedState.bookguid, apiModel]);
  
const handleLocationSelect = (index: number) => {
  if (locations && index >= 0 && index < locations.length) {
    setSelectedLocation(index);
    setCurrentLocationName(locations[index].name);
    setCurrentLocationDescription(locations[index].description);
  } else {
    console.error("Selected index is out of bounds or locations are not loaded");
  }
};

const updateLocation = async (updatedLocation: Location) => {
    if (selectedLocation !== null) {
      const updatedLocations = [...locations];
      updatedLocations[selectedLocation] = updatedLocation;
      setLocations(updatedLocations);
      await handleBlurSave('locations', JSON.stringify({locations: updatedLocations}));
    }
};

const handleAddLocation = async () => {
    const newLocation = {
      name: 'My New Location',
      description: ''
    };
    const updatedLocations = [...locations, newLocation];
    setLocations(updatedLocations);
    await handleBlurSave('locations', JSON.stringify({locations: updatedLocations}));
    setSelectedLocation(updatedLocations.length - 1);
    setCurrentLocationName(newLocation.name);
    setCurrentLocationDescription(newLocation.description);
};


const handleDeleteLocation = (index: number) => {
  Modal.confirm({
    title: 'Are you sure you want to delete this location?',
    content: 'This action cannot be undone.',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: async () => {
        const updatedLocations = [...locations];
        updatedLocations.splice(index, 1);
        setLocations(updatedLocations);
        await handleBlurSave('locations', JSON.stringify({locations: updatedLocations}));
 
        if (updatedLocations.length > 0) {
          const lastLocationIndex = updatedLocations.length - 1;
          setSelectedLocation(lastLocationIndex);
          setCurrentLocationName(updatedLocations[lastLocationIndex].name);
          setCurrentLocationDescription(updatedLocations[lastLocationIndex].description);
        } else {
          setSelectedLocation(null);
          setCurrentLocationName('');
          setCurrentLocationDescription('');
        }
    },
  });
};
    
const handleBlurSave = async (field: string, value: any) => {

  if (sharedState.bookguid && isAuth && user) {
    try {
      const bookRef = doc(db, 'books', sharedState.bookguid);
      await updateDoc(bookRef, {[field]: value, lastModifiedDate: Timestamp.now()});
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }
};

  const handleInfoModal = (visible: boolean, source: string) => {
    if (source === 'Locations')
	{
	setInfoTitle("Locations Information");
    setInfoContent(`ScribeShadow can generate a list of locations for your story, including their descriptions and backgrounds. 
	
	ScribeShadow will use the Genre, Tropes, Plot, Main Characters, and Supporting Characters provided so far when creating your locations. You can also write your own locations.
	
	Any character names or location names included in the Basic Plot, Main Characters, or Supporting Characters could be used when ScribeShadow generates locations, so make sure you are happy with them before continuing.
	
	ScribeShadow will use the Locations when generating your Outline.`);
    }	
    setIsInfoModalVisible(visible);
  };

  let infoModalContent = (
    <p>
      {infoContent.split('\n').map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}
    </p>
  );
    
  const handleErrorModal = (visible: boolean) => {
    setErrorModalVisible(visible);
  };
  
  // Confirmation handlers
const handleConfirmLocationsModal = async (confirm: boolean) => {
  setIsConfirmLocationsModalVisible(false);
  if (confirm) {
    handleGenerateLocations();
  }
};

const handleGenerateLocationsClick = async () => {
  //if (sharedState.locations.trim() !== '' && !shouldSkipLocationsConfirm) {
  //  setIsConfirmLocationsModalVisible(true);
  //} else {
    handleGenerateLocations();
  //}
};

const handleGenerateLocations = async () => {
  setIsLoading(true);
  try {
    
    const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate_locations/', { 
      tropes: bookTropes,
      plot: act1+' '+act2+' '+act3,
	  bookguid: sharedState.bookguid,
      locations:JSON.stringify(locations),
	  apimodel: apiModel,
      genre: bookGenre,
      userID: usersSharedState.userID
    });

    const updatedLocations = JSON.parse(response.data.locations).locations || [];
    const newLocations = locations.slice(); // create a copy of existing locations

  updatedLocations.forEach((location: Location) => {
    const locationExists = locations.some((existingLocation: Location) => existingLocation.name === location.name);
    if (!locationExists && newLocations.length < usersSharedState.maxCharacters) {
      newLocations.push(location);
    }
  });

    setLocations(newLocations);


    if (sharedState.bookguid && isAuth && user) {
      try {
        const bookRef = doc(db, 'books', sharedState.bookguid);
        await updateDoc(bookRef, {locations: JSON.stringify({locations: newLocations}), lastModifiedDate: Timestamp.now()});
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }

  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage('An error occurred while generating the locations.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

const [isRegeneratingLocation, setIsRegeneratingLocation] = useState(false);

const regenerateLocation = async (operationType: 'name' | 'description') => {
setIsLoading(true);
  if (selectedLocation === null) {
    return; // No location is selected.
  }

  setIsRegeneratingLocation(true);
  try {
    const location = locations[selectedLocation];
	const locationString = `Name: ${location.name}, Description: ${location.description}`;


    const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/regenerate_location/', { 
      apimodel: apiModel,
      operationType,
      currentLocation: locationString,
      genre: bookGenre,
      plot: act1+' '+act2+' '+act3,
      locations: JSON.stringify(locations),
      tropes: bookTropes,
	  bookguid: sharedState.bookguid,
      userID: usersSharedState.userID
    });

    const updatedLocation = JSON.parse(response.data.location).locations[0];
    const updatedLocations = [...locations]; // Create a copy of the locations array
    updatedLocations[selectedLocation] = updatedLocation; // Update the specific location
    setLocations(updatedLocations); // Set the updated array back to state

    setCurrentLocationName(updatedLocation.name);
    setCurrentLocationDescription(updatedLocation.description);
    handleBlurSave('locations', JSON.stringify({locations: updatedLocations}));


  } catch (err) {
    // handle error
  } finally {
    setIsRegeneratingLocation(false);
	setIsLoading(false);
  }
};


return (
    <div>
      {!sharedState.bookguid && isAuth && <Tooltip title="Please start or open a book in the Book Management tab."><p><b>Please start or open a book in the Book Management tab.<br/>Fields will become available once a book is in progress.</b></p></Tooltip>}
      {!isAuth && <Tooltip title="Please click Get Started."><p><b>Click Get Started to sign up and start generating books!</b></p></Tooltip>}
      <Row gutter={16}>
        <Col span={6}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{marginRight: '5px', fontSize: '16px'}}><b>Locations</b></p>
              <Tooltip title="Click for more information">
                <InfoCircleOutlined
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => handleInfoModal(true, 'Locations')}
                />
              </Tooltip>
            </div>
			
            <StyledList
              dataSource={locations}
              renderItem={(item: any, index: number) => (
<List.Item
  key={index}
  style={{ 
    cursor: 'pointer',
    backgroundColor: index === selectedLocation ? '#e6f7ff' : undefined  
  }} 
  onClick={() => handleLocationSelect(index)}
  actions={[

<Dropdown 
  overlay={
    <Menu>
<Menu.Item key="1" onClick={() => {
  regenerateLocation('name');
  setVisibleMainDropdownIndex(-1);
}}>
  Regenerate Location Name 
  <Tag color="blue" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
    {costState.genLocationNameCost} Credit{costState.genLocationNameCost > 1 ? 's' : ''}
  </Tag>
</Menu.Item>
<Menu.Item key="2" onClick={() => {
  regenerateLocation('description');
  setVisibleMainDropdownIndex(-1);
}}>
  Regenerate Location Description 
  <Tag color="blue" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
    {costState.genLocationDetailsCost} Credit{costState.genLocationDetailsCost > 1 ? 's' : ''}
  </Tag>
</Menu.Item>
<Menu.Item key="4" onClick={() => {
  handleDeleteLocation(index);
  setVisibleMainDropdownIndex(-1);
}}>Delete Location</Menu.Item>
 </Menu>
  }
  trigger={['click']}
  visible={visibleMainDropdownIndex  === index}
  onVisibleChange={(visible) => {
    if (!visible) {
      setVisibleMainDropdownIndex(-1);
    }
  }}
>
  <MoreOutlined
    style={{ cursor: 'pointer', fontSize: '16px', color: '#000' }}
    onClick={(event) => {
      event.stopPropagation(); // Prevent triggering the onClick event of the List.Item
      if (selectedLocation !== index) {
        handleLocationSelect(index);
      }
      setVisibleMainDropdownIndex(visibleMainDropdownIndex === index ? -1 : index);
    }}
  />
</Dropdown>

  ]}
>
  {item.name}
</List.Item>
              )}
            />

<Tooltip title={locations.length >= usersSharedState.maxCharacters ? `You have reached the maximum number of locations. ${usersSharedState.maxCharacters < 5 ? "Upgrade your account to increase the limit." : ""}` : ""}>
<Button 
  style={{ marginTop: '10px' }} 
  onClick={handleAddLocation} 
  disabled={!isAuth || !sharedState.bookguid || locations.length >= usersSharedState.maxCharacters}
>
  Manually Add Location
</Button>
</Tooltip>
<Tooltip title={locations.length >= usersSharedState.maxCharacters ? `You have reached the maximum number of locations. ${usersSharedState.maxCharacters < 5 ? "Upgrade your account to increase the limit." : ""}` : ""}>
<Button 
  style={{ marginTop: '10px' }} 
  onClick={handleGenerateLocationsClick} 
  disabled={!isAuth || !sharedState.bookguid || locations.length >= usersSharedState.maxCharacters}
>
  Generate Locations
</Button>
</Tooltip>
            {costState.genLocationsCost !== null && (
			<Tag color="blue" style={{ fontWeight: 'bold', marginTop: '5px' }}>
			{costState.genLocationsCost} {costState.genLocationsCost > 1 ? 'Credits' : 'Credit'}
			</Tag>
            )}
          </div>
        </Col>
        <Col span={18}>
          <p style={{fontSize: '16px'}}><b>Location Details</b></p>
          <p style={{marginBottom: '1px'}}>Location Name:</p>
  <Input 
    value={currentLocationName} 
    onChange={e => setCurrentLocationName(e.target.value)}
    onBlur={() => updateLocation({name: currentLocationName, description: currentLocationDescription})}
    disabled={!isAuth || !sharedState.bookguid} // Disable when not authenticated or book is not opened
  />
  <p style={{marginBottom: '1px', marginTop: '10px'}}>Location Description:</p>
  <TextArea 
    rows={10} 
    value={currentLocationDescription} 
    onChange={e => setCurrentLocationDescription(e.target.value)}
    onBlur={() => updateLocation({name: currentLocationName, description: currentLocationDescription})}
    disabled={!isAuth || !sharedState.bookguid} // Disable when not authenticated or book is not opened
  />
        </Col>
      </Row>
      <Modal 
        title={infoTitle}
        visible={isInfoModalVisible}
        onOk={() => handleInfoModal(false, "")}
        onCancel={() => handleInfoModal(false, "")}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="submit" type="primary" onClick={() => handleInfoModal(false, "")}>
              OK
            </Button>
          </div>
        }
      >
        {infoModalContent}
      </Modal>
	  
	  <Modal 
	  title="Error"
	  visible={isErrorModalVisible}
	  onOk={() => setErrorModalVisible(false)}
	  onCancel={() => setErrorModalVisible(false)}
	  footer={
	 	 <div style={{ textAlign: 'center' }}>
	 	 <Button key="submit" type="primary" onClick={() => setErrorModalVisible(false)}>
	 		 OK
	 	 </Button>
	 	 </div>
	  }
	  >
	  <p>{errorMessage}</p>
	  </Modal>
	  
	  <Modal
	  title={null}
	  visible={isLoading}
	  footer={null}
	  centered
	  closable={false}
	  maskClosable={false}
	  style={{ 
	 	 textAlign: 'center', 
	 	 background: 'transparent', 
	 	 boxShadow: 'none',
	 	 width: 'auto'
	  }}
	  >
	  <img src="generating.gif" alt="Generating..." className="responsive-gif" />
	  </Modal>	  

	  <Modal 
	  title="Are you sure you want to regenerate your Locations?"
	  visible={isConfirmLocationsModalVisible}
	  onOk={() => handleConfirmLocationsModal(true)}
	  onCancel={() => handleConfirmLocationsModal(false)}
	  >
	  <p>If you proceed, the existing Locations text will be deleted and replaced. <br/>
	    
	  Regenerating Locations will NOT change any other text fields already generated.<br/><br/>
	  
	  Do you want to continue?</p>
	  <Checkbox onChange={(e) => setShouldSkipLocationsConfirm(e.target.checked)}>Don't show this again for this session</Checkbox>
	  </Modal>
	  	  
    </div>
  );
};

export default LocationsTab;
