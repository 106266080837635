// BookUploadForm.tsx

import React, { useState, useEffect } from 'react';
import { Button, Input, Upload, Select, message } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getAuth } from "firebase/auth";
import axios from 'axios';
import { RcFile } from 'antd/es/upload';

interface BookUploadFormProps {
  onUpload: (formData: FormData) => Promise<void>;
  onReset: () => void;
  authors: string[];
  seriesList: string[];
}

const BookUploadForm: React.FC<BookUploadFormProps> = ({ onUpload, onReset, authors, seriesList }) => {
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState<string>('');
  const [author, setAuthor] = useState<string>('');
  const [series, setSeries] = useState<string>('');
  const [isBetaUser, setIsBetaUser] = useState(false);
  const baseLanguages = ["Dutch", "English", "British English", "French", "German", "Italian", "European Portuguese", "Brazilian Portuguese","Spanish" ];
  const betaLanguages = ["Japanese", "Traditional Chinese", "Simplified Chinese", "Russian", "Thai", "Catalan"];
  const LANGUAGES = isBetaUser ? [...baseLanguages, ...betaLanguages] : baseLanguages;  
  const [language, setLanguage] = useState<string>('English');
  const [seriesBookNumber, setSeriesBookNumber] = useState<string>('');
  const [seriesNumberError, setSeriesNumberError] = useState<string>('');
  const [fileSelectionError, setFileSelectionError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);
  const [authorDropdownOpen, setAuthorDropdownOpen] = useState(false);
  const [seriesDropdownOpen, setSeriesDropdownOpen] = useState(false);


  const auth = getAuth();

  useEffect(() => {
    const checkBetaStatus = async () => {
      const userId = auth.currentUser?.uid;
      if (userId) {
        try {
          const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/check-beta-status/${userId}`);
          setIsBetaUser(response.data.isBetaUser);
        } catch (error) {
          console.error('Error checking beta status:', error);
        }
      }
    };
    
    checkBetaStatus();
  }, [auth.currentUser?.uid]);

  const validateSeriesNumber = (value: string) => {
    const isValidNumber = /^(\d+(\.\d+)?)?$/.test(value);
    if (!isValidNumber) {
      setSeriesNumberError('Series number must be a numeric value (decimals allowed)');
      return false;
    } else {
      setSeriesNumberError('');
      return true;
    }
  };

  const handleSeriesNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSeriesBookNumber(value);
    validateSeriesNumber(value);
  };

  const resetForm = () => {
    setFile(null);
    setTitle('');
    setAuthor('');
    setSeries('');
    setSeriesBookNumber('');
  };

const handleAuthorChange = (value: string[]) => {
  if (value.length > 0) {
    const selectedAuthor = value[value.length - 1];
    setAuthor(selectedAuthor);
    // Close the dropdown
    setAuthorDropdownOpen(false);
  } else {
    setAuthor('');
  }
};


const handleSeriesChange = (value: string[]) => {
  if (value.length > 0) {
    const selectedSeries = value[value.length - 1];
    setSeries(selectedSeries);
    // Close the dropdown
    setSeriesDropdownOpen(false);
  } else {
    setSeries('');
  }
};


  const handleBeforeUpload = (file: RcFile) => {
    const isLt30M = file.size / 1024 / 1024 < 30;
    if (!isLt30M) {
      setFileSizeError('File must be smaller than 30MB!');
    } else {
      setFileSizeError(null);
    }
    return false;
  };

  const submitForm = async () => {
    if (file && validateSeriesNumber(seriesBookNumber)) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', title);
      formData.append('author', author);
      formData.append('series', series);
      formData.append('language', language);
      formData.append('seriesBookNumber', seriesBookNumber);
      onReset();

      try {
        await onUpload(formData);
        resetForm();
      } catch (error: any) {
        const errorMsg = error.response?.data?.message || error.message;
        if (errorMsg?.includes('longer than')) {
          message.error("Error Uploading Book. Please ensure you select the correct language and chapters are properly labeled and/or separated by page breaks.", 10);
        } else {
          message.error(errorMsg || "Error uploading book", 10);
        }
      }
    }
  };

  return (
    <div>
      <p><center>
        
        <a href="/support#videoTutorial" target="_blank">View Tutorial</a> | <a href="/support#word-formatting-guidelines" target="_blank">View Word Formatting Guidelines</a></center>
      </p>	
	  <div style= {{marginBottom: '4px'}}><center>Accepted file formats: .docx, .epub.</center></div>
      <Upload.Dragger
        name="file"
        accept=".docx,.epub"
        style={{ marginBottom: '1rem', width: '100%' }}
        multiple={false}
        beforeUpload={handleBeforeUpload}
        onChange={({ fileList }) => {
          if (fileList.length > 1) {
            setFileSelectionError(true);
          } else {
            setFileSelectionError(false);
            const originFileObj = fileList[0]?.originFileObj;
            if (originFileObj && originFileObj.size / 1024 / 1024 < 30) {
              setFile(originFileObj as File);
            } else {
              setFile(null);
            }
          }
        }}
        onRemove={() => {
          setFile(null);
          setFileSelectionError(false);
        }}
      >
        <p className="ant-upload-text">Click or drag book file to upload</p>
      </Upload.Dragger>
	  {fileSizeError && <div style={{ color: 'red', marginTop: '10px' }}><ExclamationCircleOutlined style={{ marginRight: '8px' }} />{fileSizeError}</div>}
	  {fileSelectionError && <div style={{ color: 'red', marginTop: '10px' }}><ExclamationCircleOutlined style={{ marginRight: '8px' }} />Only one file may be uploaded at a time.</div>}
      <label htmlFor="title" style={{ marginTop: '10px' }}>Title:</label>
      <Input placeholder="Title" value={title} style={{ marginBottom: '1rem', width: '100%' }} onChange={(e) => setTitle(e.target.value)} />
      
      <label htmlFor="Author" style={{ marginTop: '10px' }}>Author:</label>
<Select
  mode="tags"
  placeholder="Author"
  value={author ? [author] : []}
  onChange={handleAuthorChange}
  style={{ marginBottom: '1rem', width: '100%' }}
  open={authorDropdownOpen}
  onDropdownVisibleChange={(open) => setAuthorDropdownOpen(open)}
>
  {authors.map((authorItem) => (
    <Select.Option key={authorItem} value={authorItem}>{authorItem}</Select.Option>
  ))}
</Select>
      
      <label htmlFor="Series" style={{ marginTop: '10px' }}>Series Name:</label>
<Select
  mode="tags"
  placeholder="Series Name (optional)"
  value={series ? [series] : []}
  onChange={handleSeriesChange}
  style={{ marginBottom: '1rem', width: '100%' }}
  open={seriesDropdownOpen}
  onDropdownVisibleChange={(open) => setSeriesDropdownOpen(open)}
>
  {seriesList.map((seriesItem) => (
    <Select.Option key={seriesItem} value={seriesItem}>{seriesItem}</Select.Option>
  ))}
</Select>
      
      <label htmlFor="bookNbr" style={{ marginTop: '10px' }}>Number in Series:</label>
      <Input
        placeholder="Series Book Number (optional)"
        value={seriesBookNumber}
        onChange={handleSeriesNumberChange}
        style={{ marginBottom: '1rem' }}
      />
      {seriesNumberError && <div style={{ color: 'red', marginTop: '10px' }}><ExclamationCircleOutlined style={{ marginRight: '8px' }} />{seriesNumberError}</div>}
      
      <label htmlFor="Language" style={{ marginTop: '10px' }}>Book's Current Language:</label>
      <Select
        placeholder="Select Language"
        value={language}
        onChange={setLanguage}
        style={{ marginBottom: '1rem', width: '100%' }}
      >
        {LANGUAGES.map(lang => (
          <Select.Option key={lang} value={lang}>
            {betaLanguages.includes(lang) ? `${lang} (BETA)` : lang}
          </Select.Option>
        ))}
      </Select> 
      
      <Button 
        type="primary" 
        onClick={submitForm} 
        disabled={!file || !title || !author || !language || fileSelectionError || !!fileSizeError}
        style={{ marginTop: '1rem' }}
      >
        Submit
      </Button>
    </div>
  );
};

export default BookUploadForm;