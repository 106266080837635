// Create.tsx
import React, { useState } from 'react';
import { Tabs, Alert, Tooltip  } from 'antd';
import HighLevelTab from './Create/HighLevelTab';
import BasicPlotTab from './Create/BasicPlotTab';
import CharactersTab from './Create/CharactersTab';
import LocationsTab from './Create/LocationsTab';
import OutlineTab from './Create/OutlineTab';
import NovelTab from './Create/NovelTab';
import BookManagementTab from './Create/BookManagementTab';
import MetadataTab from './Create/MetadataTab';
import ExpandedPlotTab from './Create/ExpandedPlotTab';
import PromotionalTab from './Create/PromotionalTab';
import AudiobookTab from './Create/AudiobookTab';
import { User } from "firebase/auth";
import { Timestamp  } from "firebase/firestore"; 
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const { TabPane } = Tabs;


interface CreateProps {
  isAuth: boolean;
  user: User | null;
  sharedState: any; // Define the appropriate type here
  setSharedState: any; // Define the appropriate type here
  usersSharedState: any; // Define the appropriate type here
  setUsersSharedState: any; // Define the appropriate type here
}


const currentTime = Timestamp.now();

const Create: React.FC<CreateProps> = ({ isAuth, user, sharedState, setSharedState, usersSharedState, setUsersSharedState }) => {

  
  const [activeTabKey, setActiveTabKey] = useState("1");
  const isBookGuidEmpty = !sharedState.bookguid;
  const [isBetaAlertVisible, setIsBetaAlertVisible] = useState(true);

  const renderTabLabel = (label: string, disabled: boolean) => (
    <Tooltip title={disabled ? "This feature is temporarily disabled while we work to improve it." : ""}>
      <span>{label}</span>
    </Tooltip>
  );  

  return (
    <div style={{ background: '#f0f2f5', minHeight: '110vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ background: '#fff', padding: '10px', minHeight: '100vh', width: '90%', margin: '5% auto', borderRadius: '5px' }}>
        {/* Beta Message */}
{isBetaAlertVisible && (
  <Alert
    message="Beta Feature"
    description="The novel creation features are currently in a beta state. Your feedback is appreciated!"
    type="warning"
    showIcon
    banner
    closable
    onClose={() => setIsBetaAlertVisible(false)}
    style={{ marginBottom: '20px' }}
  />
)}  
	  <div style={{textAlign: 'right', margin: '0px'}}>
	  <a href="/support#create-faq">Support & FAQs</a>
	  </div>
	  <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={setActiveTabKey}>
        <TabPane tab="Book Management" key="1">
          <BookManagementTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} setActiveTabKey={setActiveTabKey} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
        </TabPane>
        <TabPane tab="High Level" key="2" disabled={isBookGuidEmpty}>
          <HighLevelTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
        </TabPane>
        <TabPane tab="Basic Plot" key="3" disabled={isBookGuidEmpty}>
          <BasicPlotTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
        </TabPane>
		<TabPane tab="Characters" key="4" disabled={isBookGuidEmpty}>
          <CharactersTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
        </TabPane>
		<TabPane tab="Locations" key="5" disabled={isBookGuidEmpty}>
          <LocationsTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
        </TabPane>
		{/* <TabPane tab="Expanded Plot" key="9" disabled={isBookGuidEmpty}>
          <ExpandedPlotTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
        </TabPane> */}
          <TabPane tab={renderTabLabel("Outline", true)} key="6" disabled={isBookGuidEmpty}>
            <OutlineTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
          </TabPane>
          <TabPane tab={renderTabLabel("Novel", true)} key="7" disabled={isBookGuidEmpty}>
            <NovelTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
          </TabPane>
          <TabPane tab={renderTabLabel("Metadata", true)} key="8" disabled={isBookGuidEmpty}>
            <MetadataTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
          </TabPane>	
{/*        
	 	<TabPane tab={renderTabLabel("Detailed Outline", true)} key="6" disabled={true}>
            <OutlineTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
          </TabPane>
          <TabPane tab={renderTabLabel("Novel", true)} key="7" disabled={true}>
            <NovelTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
          </TabPane>
          <TabPane tab={renderTabLabel("Metadata", true)} key="8" disabled={true}>
            <MetadataTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
          </TabPane>			  
*/}		  
		{/*
		<TabPane tab="Promotional" key="9">
          <PromotionalTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
        </TabPane>				
		<TabPane tab="Audiobook" key="10">
          <AudiobookTab sharedState={sharedState} setSharedState={setSharedState} isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} />
        </TabPane>						
		*/}
		
      </Tabs>


    </div>
	</div>
  );
};

export default Create;
