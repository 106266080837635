//PasswordResetComponent.tsx

import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';

interface Props {
  isResetModalVisible: boolean;
  handleResetModalCancel: () => void;
  resetPassword: (email: string) => void;
}

const PasswordResetComponent: React.FC<Props> = ({ isResetModalVisible, handleResetModalCancel, resetPassword }) => {
  const [isResetEmailSent, setIsResetEmailSent] = useState(false);

  const onFormFinish = (email: string) => {
    resetPassword(email);
    setIsResetEmailSent(true);
  };

  const resetForm = () => {
    setIsResetEmailSent(false);
    handleResetModalCancel();
  };

  return (
    <Modal
      title="Reset Password"
      visible={isResetModalVisible}
      onCancel={resetForm}
      footer={null} // Remove default footer
      centered
    >
      {isResetEmailSent ? (
        <div>
          <p style={{ textAlign: 'center' }}>A password reset link has been sent to your email.</p>
          <Button type="primary" block onClick={resetForm}>Close</Button>
        </div>
      ) : (
        <Form layout="vertical" onFinish={({ email }) => onFormFinish(email)}>
          <p style={{ textAlign: 'center' }}>Enter your email to get a password reset link:</p>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit">
              Send reset link
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default PasswordResetComponent;
