// KeywordSuggestionModal.tsx

import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Checkbox, Tooltip, message, Spin, Tag } from 'antd';
import { QuestionCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

interface KeywordSuggestionModalProps {
  isVisible: boolean;
  toggleModal: () => void;
  bookId: string;
  bookLanguage: string;
  genres: string;
  tropes: string[];
  keywords: string[];
  blurb: string;
  selectedAIModel: string;
  fetchCreditBalance: (userId: string) => Promise<void>;
  creditBalance: { credit_balance: number } | null;
  auth: any;
  languages: string[];
  betaLanguages: string[];
  fetchBookDetails: () => void; 
  isLoading: boolean; 
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  targetLanguage: string;
}

const KeywordSuggestionModal: React.FC<KeywordSuggestionModalProps> = ({
  isVisible,
  toggleModal,
  bookId,
  bookLanguage,
  genres,
  tropes,
  keywords,
  blurb,
  selectedAIModel,
  fetchCreditBalance,
  creditBalance,
  auth,
  languages,
  betaLanguages,
  fetchBookDetails,
  isLoading,
  setIsLoading,  
  targetLanguage,
}) => {
  const [keywordAIModel, setKeywordAIModel] = useState<string>('');
  const [targetLang, setTargetLang] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState({
    genre: false,
    tropes: false,
    keywords: false,
    blurb: false,
  });

  useEffect(() => {
    setKeywordAIModel(selectedAIModel);
    // Update targetLang based on targetLanguage prop
    setTargetLang(targetLanguage || '');
  }, [selectedAIModel, targetLanguage]);

  const isAnyOptionSelected = Object.values(selectedOptions).some(option => option);

  const calculateKeywordSuggestionCost = () => {
    let cost = 0;
    if (isAnyOptionSelected) {
      cost = 500;
    }
    return cost;
  };

  const userHasEnoughCredits = creditBalance?.credit_balance ?? 0 >= calculateKeywordSuggestionCost();

  const handleKeywordSuggestions = async () => {
    if (auth.currentUser) {
	
	  setIsLoading(true);	
		
      const payload = {
        userId: auth.currentUser.uid,
        bookId: bookId,
        originLanguage: bookLanguage,
        targetLanguage: targetLang,
        options: selectedOptions,
        model: keywordAIModel,
      };

      let success = false;

      try {
        toggleModal();
        const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/suggest-keywords/', payload);
        success = true;
		fetchBookDetails();
      } catch (error) {
        success = false;
      } finally {
		setIsLoading(false);  
        if (success) {
          message.success("Keywords generated successfully.");
        } else {
          message.error("Error generating keywords.");
        }
      }
    }
  };

const renderCheckbox = (label: string, value: boolean, onChange: (e: any) => void, disabled: boolean, tooltip: string) => {
  const displayLabel = label === 'Keywords' ? `${bookLanguage} Keywords` : label;
  
  return (  
    <Tooltip title={disabled ? tooltip : ''}>
      <Checkbox checked={value} onChange={onChange} disabled={disabled}>
        {displayLabel}
      </Checkbox>
    </Tooltip>
  )
};

  return (
    <Modal
      title="Keyword Suggestions"
      visible={isVisible}
      onCancel={toggleModal}
      footer={[
        <Button key="cancel" onClick={toggleModal}>Cancel</Button>,
        <Button key="suggest" type="primary" onClick={handleKeywordSuggestions} disabled={!isAnyOptionSelected || !userHasEnoughCredits || !targetLang}>
          Generate Keywords
        </Button>,
      ]}
    >
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px', fontWeight: 'bold' }}>Origin Language:</label>
        <div>{bookLanguage}</div>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px', fontWeight: 'bold' }}>Target Language:</label>
        <Select
          value={targetLang}
          onChange={(value) => setTargetLang(value)}
          style={{ width: '100%' }}
        >
          {languages.filter(lang => lang !== bookLanguage).map(lang => (
            <Select.Option key={lang} value={lang}>
              {lang} {betaLanguages.includes(lang) ? "(BETA)" : ""}
            </Select.Option>
          ))}
        </Select>    
      </div>

      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px', fontWeight: 'bold' }}>AI Model:</label>
        <Select
          value={keywordAIModel}
          onChange={(value) => setKeywordAIModel(value)}
          style={{ width: '100%' }}
        >
          <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>
          <Select.Option value="Claude">Claude 3.5 <Tag color="geekblue" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Recommended <CheckCircleOutlined /></Tag></Select.Option>
        </Select>
      </div>
      
      <p>Check what data you want ScribeShadow to use when suggesting keywords. For best results, populate Keywords, Genre, and Tropes in your metadata.</p>
      {renderCheckbox('Genre', selectedOptions.genre, (e) => setSelectedOptions({ ...selectedOptions, genre: e.target.checked }), genres.length === 0, "Genre has not been provided.")}
      <br />
      {renderCheckbox('Tropes', selectedOptions.tropes, (e) => setSelectedOptions({ ...selectedOptions, tropes: e.target.checked }), tropes.length === 0, "Tropes have not been provided.")}
      <br />
      {renderCheckbox('Keywords', selectedOptions.keywords, (e) => setSelectedOptions({ ...selectedOptions, keywords: e.target.checked }), keywords.length === 0, "Keywords have not been provided.")}
      <br />
      {renderCheckbox('Blurb', selectedOptions.blurb, (e) => setSelectedOptions({ ...selectedOptions, blurb: e.target.checked }), blurb.length === 0, "Blurb has not been provided.")}
      
      <div style={{ marginTop: '10px' }}>
        <label>Credit Balance: </label>
        <span>{creditBalance?.credit_balance ?? 0}</span><br />
        <label>Credit Cost: </label>
        <span>{calculateKeywordSuggestionCost()}</span>
      </div>
      {!userHasEnoughCredits && <div style={{ color: 'red' }}>You do not have enough credits for this operation.</div>}
	</Modal>
	
  );
};

export default KeywordSuggestionModal;