//Account.tsx
import React, { useState, useEffect  } from 'react';
import { Card, Switch, Typography, Button, Modal, Divider, Row, Col, Descriptions, Space, Grid, Tooltip, Select, message, Collapse  } from 'antd';
import { Radio } from 'antd';
import { CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CheckoutForm } from './CheckoutForm'; 
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UsersSharedState } from './Create/types';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Spin, Input } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph  } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;

type PlanType = {
  name: string;
  monthlyPrice: string;
  yearlyPrice: string;
  credits: string;
  description: string;
  isPopular?: boolean;
};

interface AccountProps {
  isAuth: boolean;
  usersSharedState: any;
  setUsersSharedState: any;
  showLoginModal: () => void;
}

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: '10px',
  overflow: 'hidden',
};

const lastPanelStyle = {
  ...customPanelStyle,
  borderBottom: '1px solid #d9d9d9',
};

interface PlanDetails {
  planName?: string;
  monthlyCredits?: number;
  tier?: number;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const Account: React.FC<AccountProps> = ({ isAuth, usersSharedState, setUsersSharedState, showLoginModal  }) => {
  
  const [isYearly, setIsYearly] = useState(false);
  const [planDetails, setPlanDetails] = useState<PlanDetails>({});
  const [isCheckoutModalVisible, setisCheckoutModalVisible] = useState(false);
  const [isLoadingPlanDetails, setIsLoadingPlanDetails] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState('');
  const [foreverCredits, setForeverCredits] = useState(0); 
  const pageDisabled = false;
  const disabledMessage = "After a lot of feedback, we're making improvements. Check back soon! -Team ScribeShadow";
  const screens = useBreakpoint();
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);
  const baseLanguages = ["Dutch", "English", "British English", "French", "German", "Italian", "European Portuguese", "Brazilian Portuguese", "Spanish"];
  const [isRenewModalVisible, setIsRenewModalVisible] = useState(false);
  const [isRenewing, setIsRenewing] = useState(false);  
  const [renewalAmount, setRenewalAmount] = useState<number | null>(null);
  const [renewalPlanName, setRenewalPlanName] = useState<string>('');
  const [isLoadingRenewalDetails, setIsLoadingRenewalDetails] = useState<boolean>(false);
  const [renewalErrorMessage, setRenewalErrorMessage] = useState<string | null>(null);
  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);
  const [customReferralCode, setCustomReferralCode] = useState('');
  const [isGeneratingReferralCode, setIsGeneratingReferralCode] = useState(false);
  const [referralCodeOption, setReferralCodeOption] = useState('generate');

  
useEffect(() => {
  const fetchDetails = async () => {
    if (isAuth && usersSharedState.userID) {
      setIsLoadingPlanDetails(true);
      try {
        const creditResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/', {
          params: {
            userID: usersSharedState.userID,
          },
        });

        const planID = creditResponse.data.planID;
        const planResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/get_plan_details/${planID}`);
        const subscriptionResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/subscription-details/${usersSharedState.userID}`);
        
        setUsersSharedState({
          ...usersSharedState,
          credits: creditResponse.data.credit_balance,
          maxCharacters: subscriptionResponse.data.maxCharacters ?? 1,
          maxLocations: subscriptionResponse.data.maxLocations ?? 1,
          maxChapters: subscriptionResponse.data.maxChapters ?? 1,
          gpt4Allowed: subscriptionResponse.data.gpt4Allowed ?? false
        });
		
		setAffiliateCode(subscriptionResponse.data.affiliateCode ?? '')
		console.log("subscriptionResponse.data.affiliateCode: "+subscriptionResponse.data.affiliateCode);

        setForeverCredits(creditResponse.data.forever_credits);
		setPlanDetails({
          planName: planResponse.data.planName,
          monthlyCredits: planResponse.data.monthlyCredits,
          tier: planResponse.data.tier,
        });

        // Fetch the current default language
        const languageResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get-default-language', {
          params: { userID: usersSharedState.userID }
        });
        setDefaultLanguage(languageResponse.data.defaultLanguage);


      } catch (error) {
        console.error('Failed to fetch details:', error);
      } finally {
        setIsLoadingPlanDetails(false);
      }
    }
  };
  fetchDetails();
}, [isAuth, usersSharedState.userID, planDetails.tier]);



    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        // This function will run when the component unmounts.
        return () => {
            document.body.removeChild(script);
        }
    }, []);
  
  
  
  const handleBilling = async () => {
    try {
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/create-customer-portal-session', {
        userID: usersSharedState.userID,
      });
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Failed to open customer portal:', error);
    }
  };  
  
  
const plans: PlanType[] = [
  {
    name: 'Inkling',
    monthlyPrice: '4.99',
    yearlyPrice: '49.99',
    credits: '10,000',
    description: 'Ideal for new writers, this plan offers enough credits for short stories or sampling of AI translation.',
	isPopular: false,
  },
  {
    name: 'Storyteller',
    monthlyPrice: '24.99',
    yearlyPrice: '249.99',
    credits: '55,000',
    description: 'The Storyteller plan provides ample credits to bring your novellas to life in different languages, enhancing your storytelling reach.',
	isPopular: false,
  },
  {
    name: 'Wordsmith',
    monthlyPrice: '49.99',
    yearlyPrice: '499.99',
    credits: '120,000',
    description: 'The Wordsmith plan is suited for authors translating full-length novels monthly, perfect for authors aiming to expand their readership internationally.',
	isPopular: true,
  },
  {
    name: 'Novelist',
    monthlyPrice: '99.99',
    yearlyPrice: '999.99',
    credits: '250,000',
    description: 'For established authors, the Novelist plan offers ample credits for translating novels into multiple languages.',
	isPopular: false,
  },
  {
    name: 'Bestseller',
    monthlyPrice: '199.99',
    yearlyPrice: '1999.99',
    credits: '600,000',
    description: 'The Bestseller plan enables the translation of an extensive catalogue of works. It\'s an ideal choice for prolific authors who have a stream of content requiring translation.',
	isPopular: false,
  },
  {
    name: 'Publisher',
    monthlyPrice: '399.99',
	yearlyPrice: '0',
    credits: '1,300,000',
    description: 'The Publisher plan is perfect for publishing houses and professional writers who manage a large volume of content. This plan provides ample credits to ensure your extensive library is translated quickly.',
	isPopular: false,
  },
];

const handleDefaultLanguageChange = async (value: string) => {
  setIsUpdatingLanguage(true);
  try {
    await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-default-language', {
      userID: usersSharedState.userID,
      language: value
    });
    setDefaultLanguage(value);
    message.success('Default language updated successfully');
  } catch (error) {
    console.error('Failed to update default language:', error);
    message.error('Failed to update default language');
  } finally {
    setIsUpdatingLanguage(false);
  }
};

const PricingCard: React.FC<{ plan: PlanType }> = ({ plan }) => {

  const commonCardStyle = {
    width: screens.md ? '375px' : '100%',
	maxWidth: '375px',
    textAlign: 'center' as const,
    margin: '10px auto',
    padding: '25px 20px 20px 20px',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    border: '1px solid #eaeaea', 
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
  };


  const popularCardStyle = {
    width: screens.md ? '375px' : '100%',
	maxWidth: '375px',
    textAlign: 'center' as const,
    margin: '10px auto', 
	padding: '0 20px 20px 20px',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    border: '1px solid #eaeaea', 
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
  };

  const popularHeaderStyle: CSSProperties = {
    backgroundColor: '#e6f7ff', 
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    position: 'relative',
    borderBottom: '1px solid #eaeaea',
  };

  const commonHeaderStyle: CSSProperties = {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottom: '1px solid #eaeaea',
  };

  const descriptionStyle: CSSProperties = {
    minHeight: '120px', // Adjust the min-height as needed to match your content
  }; 

  return (
    <Card
      style={plan.isPopular ? popularCardStyle : commonCardStyle}
      headStyle={plan.isPopular ? popularHeaderStyle : commonHeaderStyle}
      bodyStyle={{
        padding: '20px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {plan.isPopular && (
        <div style={{ color: '#1890ff', zIndex: 1, textAlign: 'center' }}>
          <CheckCircleOutlined /> <strong> Most popular </strong>
        </div>
      )}
      <Title level={4}>{plan.name}</Title>
	  <Divider />
      <div style={descriptionStyle}>
        <Paragraph>{plan.description}</Paragraph>
      </div>
      <div>
        <Paragraph strong>This includes:</Paragraph>
        <Paragraph>{plan.credits} monthly translation credits</Paragraph>
        <Title level={4}>${plan.monthlyPrice}</Title>
        <Paragraph>per month</Paragraph>
        {plan.yearlyPrice !== '0' && plan.yearlyPrice !== 'N/A' ? (
          <Paragraph>${plan.yearlyPrice} when billed yearly</Paragraph>
        ) : (
          <Paragraph type="secondary">Yearly billing is not available</Paragraph>
        )}
      </div>
      <Button type="primary" onClick={showLoginModal}>Log In to Purchase</Button>
      <Text type="secondary" style={{ marginTop: '10px', fontSize: '12px' }}>
        Each translated word costs approximately one credit.
      </Text>	  
    </Card>
  );
};


const handleRenewNowClick = async () => {
  setIsRenewModalVisible(true);
  setIsLoadingRenewalDetails(true);
  setRenewalErrorMessage(null); // Reset the error message
  try {
    const response = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get-subscription-details', {
      params: { userID: usersSharedState.userID },
    });

    const { amount_due, plan_name, status, message } = response.data;
    
    if (status === 'cancelled') {
	  setRenewalErrorMessage(message || 'Your subscription has been cancelled.');
    } else {
      setRenewalAmount(amount_due);
      setRenewalPlanName(plan_name);
    }
  } catch (error) {
    console.error('Failed to fetch subscription details:', error);
    let message = 'Failed to fetch subscription details. Please try again later.';
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      if (errorData.detail) {
        message = errorData.detail;
      }
    }
    setRenewalErrorMessage(message);
  } finally {
    setIsLoadingRenewalDetails(false);
  }
};


const handleRenewNowConfirm = async () => {
  setIsRenewing(true);
  try {
    await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/renew-subscription', {
      userID: usersSharedState.userID,
    });
    message.success('Your subscription has been renewed successfully.');
    await fetchUpdatedPlanDetails(); // Fetch updated plan details
  } catch (error) {
    console.error('Failed to renew subscription:', error);
    message.error('Failed to renew subscription. Please try again later.');
  } finally {
    setIsRenewing(false);
    setIsRenewModalVisible(false);
  }
};

const handleRenewNowCancel = () => {
  setIsRenewModalVisible(false);
  setRenewalErrorMessage(null); // Reset error message when modal is closed
};

const fetchUpdatedPlanDetails = async () => {
  setIsLoadingPlanDetails(true);
  try {
    const creditResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/', {
      params: {
        userID: usersSharedState.userID,
      },
    });

    const planID = creditResponse.data.planID;
    const planResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/get_plan_details/${planID}`);
    const subscriptionResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/subscription-details/${usersSharedState.userID}`);
    
    setUsersSharedState({
      ...usersSharedState,
      credits: creditResponse.data.credit_balance,
      maxCharacters: subscriptionResponse.data.maxCharacters ?? 1,
      maxLocations: subscriptionResponse.data.maxLocations ?? 1,
      maxChapters: subscriptionResponse.data.maxChapters ?? 1,
      gpt4Allowed: subscriptionResponse.data.gpt4Allowed ?? false
    });

    setAffiliateCode(subscriptionResponse.data.affiliateCode ?? '');
    setForeverCredits(creditResponse.data.forever_credits);
    setPlanDetails({
      planName: planResponse.data.planName,
      monthlyCredits: planResponse.data.monthlyCredits,
      tier: planResponse.data.tier,
    });

  } catch (error) {
    console.error('Failed to fetch updated details:', error);
  } finally {
    setIsLoadingPlanDetails(false);
  }
};


const handleGenerateReferralCode = async (customCode: string | null = null) => {
  setIsGeneratingReferralCode(true);
  try {
    const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate-referral-code', {
      userID: usersSharedState.userID,
      customCode: customCode
    });
    setAffiliateCode(response.data.referralCode);
    setIsReferralModalVisible(false);
    message.success('Referral code generated successfully!');
  } catch (error) {
    console.error('Failed to generate referral code:', error);
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400 && 
          error.response.data.detail && 
          error.response.data.detail.toLowerCase().includes('already exists')) {
        message.error('This custom code is already in use. Please choose a different one.');
      } else {
        message.error('Failed to generate referral code. Please try again.');
      }
    } else {
      message.error('Failed to generate referral code. Please try again.');
    }
  } finally {
    setIsGeneratingReferralCode(false);
  }
};

return (
  <div style={{ backgroundColor: '#f0f2f5', padding: '2em', minHeight: '100vh' }}>
    <div style={{ maxWidth: '1500px', margin: '0 auto' }}>
      {pageDisabled ? (
        <Alert message={disabledMessage} type="warning" showIcon />
      ) : (
        <>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '1em' }}>
            {isAuth ? 'My Account' : 'Pricing'}
          </Title>

          {isAuth ? (
            <Row gutter={[24, 24]}>
<Col xs={24} lg={8}>
<Card
  title={<div style={{ textAlign: 'center', fontSize: '1.5em' }}>FAQ</div>}
  style={{ height: '100%' }}
>
<Collapse>
  <Collapse.Panel header="Can I upgrade/downgrade/cancel my Subscription?" key="1" style={customPanelStyle}>
    <p>Yes, you can upgrade, downgrade, or cancel your subscription at any time. To manage your subscription click the "Manage Subscription" button in the <Link to="./account">account page</Link>.</p>
  </Collapse.Panel>
  <Collapse.Panel header="Do my subscription credits expire?" key="2" style={customPanelStyle}>
    <p>Yes, credits granted with your monthly subscription expire at the end of your subscription period. Forever credits, which do not expire, can also be purchased a la carte.</p>
  </Collapse.Panel>  
  <Collapse.Panel header="When does my subscription renew?" key="3" style={customPanelStyle}>
    <p>Subscriptions renew monthly and the date and TIME you signed up. So if you signed up on the 17th at 2:45pm, your account will renew each month on the 17th at 2:45pm.</p>
  </Collapse.Panel>
<Collapse.Panel header="How does billing work when I upgrade or downgrade my plan?" key="6" style={customPanelStyle}>
  <p>When you upgrade or downgrade your plan, changes don't take effect until your billing cycle renews. If you want to upgrade immediately, you can upgrade your plan using the "Manage Subscription" button and then use the "Renew Now" button to renew your subscription under your new plan right away.</p>
</Collapse.Panel>
<Collapse.Panel header="What does the 'Renew Now' button do?" key="7" style={customPanelStyle}>
  <p>The 'Renew Now' button allows you to immediately renew your subscription. This will charge you right away and move your billing date to today. It's useful if you want to align your billing cycle with a specific date or if you need immediate access to new credits.</p>
</Collapse.Panel>  
<Collapse.Panel header="How can I immediately upgrade my plan?" key="8" style={customPanelStyle}>
  <p>To upgrade your plan immediately:
    <ol>
      <li>Click the "Manage Subscription" button in your account page.</li>
      <li>Choose your new plan and complete the upgrade process.</li>
      <li>After upgrading, return to your account page and click the "Renew Now" button.</li>
      <li>Confirm the renewal to activate your new plan immediately.</li>
    </ol>
    This process will charge you for the new plan right away and adjust your billing cycle to start from today.
  </p>
</Collapse.Panel>
<Collapse.Panel header="Where can I find more information about pricing?" key="5" style={customPanelStyle}>
  <p>You can find detailed information about our pricing plans on our <a href="https://app.scribeshadow.com/pricing" target="_blank" rel="noopener noreferrer">pricing page</a>.</p>
</Collapse.Panel>  
  <Collapse.Panel header="Can my Referral Code be used on any plan?" key="4" style={lastPanelStyle}>
    <p>No. Referall codes cannot be redeemed when signing up for the Publisher or Polyglot plans.</p>
  </Collapse.Panel>  
</Collapse>
</Card>
</Col>		
              <Col xs={24} lg={8}>
                <Card
                  title={<div style={{ textAlign: 'center', fontSize: '1.5em' }}>Current Plan</div>}
                  style={{ height: '100%' }}
                >
                  {isLoadingPlanDetails ? (
                    <div style={{ textAlign: 'center', padding: '2em' }}>
                      <Spin size="large" />
                      <p>Loading your plan details...</p>
                    </div>
                  ) : (
                    <>
                      <Title level={4} style={{ marginBottom: '1em', textAlign: 'center' }}>{planDetails.planName}</Title>
                      <Descriptions bordered column={1} layout="vertical">
                        <Descriptions.Item label="Total Credit Balance">{usersSharedState.credits}</Descriptions.Item>
                        <Descriptions.Item label="This Month's Remaining Credits">{usersSharedState.credits - foreverCredits}</Descriptions.Item>
                        <Descriptions.Item label="Forever Credits">{foreverCredits}</Descriptions.Item>
                        <Descriptions.Item label="Monthly Credits">{planDetails.monthlyCredits}</Descriptions.Item>
                      </Descriptions>
					{planDetails.tier !== 0 && (
					<div style={{ marginTop: '1em', textAlign: 'center' }}>
						<Button type="primary" onClick={handleBilling}>Manage Subscription</Button>
					<Tooltip title={planDetails.tier === 0 ? 'You do not have an active subscription to renew.' : 'Immediately renew your subscription. This will charge you immediately and move your billing date to today.'}>
					<Button
						type="primary"
						style={{ marginLeft: '10px' }}
						onClick={handleRenewNowClick}
						disabled={planDetails.tier === 0}
					>
						Renew Now
					</Button>
					</Tooltip>						
						<div style={{ marginTop: '0.5em' }}>
						<Link to="https://app.scribeshadow.com/pricing" target="_blank" rel="noopener noreferrer">View Subscription Plans</Link>
						</div>
					</div>
					)}
                      <div style={{ textAlign: 'center' }}>
                        <Link to="/credit-history">View Credit Transaction History</Link>
                      </div>					
                    </>
                  )}
                </Card>
              </Col>
			  
              <Col xs={24} lg={8}>

				{/* Settings Card */}
				<Card 
				title={<div style={{ textAlign: 'center', fontSize: '1.5em' }}>Settings</div>} 
				style={{ marginBottom: '23px', height: '35%' }}
				>
				<div style={{ textAlign: 'center' }}>
					<Title level={4}>
					Default Translation Language
					<Tooltip title="This language will be automatically selected when you start a new translation. You can always change it for individual translations.">
						<QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
					</Tooltip>
					</Title>
					<Spin spinning={isUpdatingLanguage}>
					<Select
						style={{ width: '100%' }}
						placeholder="Select default language"
						onChange={handleDefaultLanguageChange}
						value={defaultLanguage}
						disabled={isUpdatingLanguage}
					>
						<Option value="">None</Option>
						{baseLanguages.map((language) => (
						<Option key={language} value={language}>
							{language}
						</Option>
						))}
					</Select>
					</Spin>
				</div>
				</Card>

				{/* Referral Card */}
                <Card title={<div style={{ textAlign: 'center', fontSize: '1.5em' }}>Referral Program</div>} style={{ height: '61.75%' }}>
                  {affiliateCode ? (
  <div style={{ textAlign: 'center' }}>
    <h2 style={{ marginTop: '-10px' }}>Your Referral Code:</h2>
    <h2>
	{affiliateCode}       
	<Tooltip title="Copy to clipboard">
    <CopyOutlined 
      style={{ marginLeft: '8px', cursor: 'pointer' }}
      onClick={() => {
        navigator.clipboard.writeText(affiliateCode);
        message.success('Referral code copied to clipboard!');
      }}
    />
  </Tooltip>	
  </h2>

    <Paragraph>
      How it works:
      <ul style={{ textAlign: 'left' }}>
        <li>New subscribers get 50% off their first month when they use your code.</li>
        <li>You earn 25% of the credits from their plan purchase as forever credits.</li>
        <li>Referrals are valid for new subscribers only.</li>
        <li>Your code cannot be used with Publisher or higher subscription tiers.</li>
      </ul>
    </Paragraph>
    <Paragraph>
      To use: Have your referrals enter <strong>{affiliateCode}</strong> as the promo code during checkout.
    </Paragraph>
  </div>
                  ) : (
<div style={{ textAlign: 'center' }}>
                    
                    <Paragraph>
                      Join our referral program and earn rewards!<br/>When you create a referral code:
                    </Paragraph>
                    <ul style={{ textAlign: 'left' }}>
                      <li>New users who sign up with your code get a 50% discount on their first month.</li>
                      <li>You earn 25% of the credits purchased by users you refer as forever credits.</li>
                    </ul>
                    <Paragraph>
                      Start earning rewards by sharing your love for ScribeShadow!
                    </Paragraph>
                    <Button onClick={() => setIsReferralModalVisible(true)}>
                      Generate Referral Code
                    </Button>
                  </div>
                  )}
                </Card>

              </Col>
  
            </Row>
          ) : (
            <Row gutter={[24, 24]}>
              {plans.map(plan => (
                <Col key={plan.name} xs={24} sm={12} lg={8}>
                  <PricingCard plan={plan} />
                </Col>
              ))}
            </Row>
          )}

{isAuth && (
  <Card title={ 
    <div style={{ textAlign: 'center', fontSize: '1.5em' }}>
      {planDetails.tier === 0 ? 'Upgrade Your Plan' : 'Purchase Forever Credits A la Carte'}
    </div>
  } style={{ marginTop: '2em' }}>
    {planDetails.tier === 0 ? (
      <>
        <stripe-pricing-table 
          pricing-table-id="prctbl_1Q2DRQEj6HHcTsCPhfYLEndc"
          publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
          client-reference-id={usersSharedState.userID}
        />
        <Divider />
        <stripe-pricing-table 
          pricing-table-id="prctbl_1Q2DTvEj6HHcTsCPpI5jNzxX"
          publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
          client-reference-id={usersSharedState.userID}
        />
        <Divider />
        <center><h1>Purchase Forever Credits A la Carte</h1></center>
      </>
    ) : null}
    <stripe-pricing-table 
      pricing-table-id="prctbl_1OsHRkEj6HHcTsCPwALbwTSF"
      publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
      client-reference-id={usersSharedState.userID}
    />
  </Card>
)}
        </>
      )}
    </div>
	
<Modal
  title="Renew Subscription Now"
  visible={isRenewModalVisible}
  onOk={handleRenewNowConfirm}
  onCancel={handleRenewNowCancel}
  confirmLoading={isRenewing}
  okText="Confirm"
  cancelText="Cancel"
  okButtonProps={{ disabled: !!renewalErrorMessage }}
>
  {isLoadingRenewalDetails ? (
    <div style={{ textAlign: 'center', padding: '2em' }}>
      <Spin size="large" />
      <p>Loading subscription details...</p>
    </div>
  ) : renewalErrorMessage ? (
    <div style={{ textAlign: 'center', padding: '2em' }}>
      <Alert message={renewalErrorMessage} type="error" showIcon />
    </div>
  ) : (
    <>
      <p>
        You are about to renew your subscription for the plan{' '}
        <strong>{renewalPlanName}</strong> at a cost of{' '}
        <strong>${(renewalAmount! / 100).toFixed(2)}</strong>.
      </p>
      <p>
        This will immediately renew your subscription at your current subscription level, charge you
        now, and move your billing date to today. Do you want to proceed?
      </p>
    </>
  )}
</Modal>

    <Modal
      title="Generate Referral Code"
      visible={isReferralModalVisible}
      onCancel={() => setIsReferralModalVisible(false)}
      footer={null}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Radio.Group 
          onChange={(e) => setReferralCodeOption(e.target.value)} 
          value={referralCodeOption}
        >
          <Radio value="generate">Generate random code</Radio>
          <Radio value="custom">Choose my own code</Radio>
        </Radio.Group>

        {referralCodeOption === 'generate' ? (
          <Button 
            onClick={() => handleGenerateReferralCode()} 
            loading={isGeneratingReferralCode}
            style={{ width: '100%', marginTop: '10px' }}
          >
            Generate Random Code
          </Button>
        ) : (
          <>
<Input 
  placeholder="Enter custom code (up to 15 characters, letters and numbers only)"
  value={customReferralCode}
  onChange={(e) => {
    const sanitizedValue = e.target.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '')
      .slice(0, 15);
    setCustomReferralCode(sanitizedValue);
  }}
  style={{ width: '100%', marginTop: '10px' }}
/>
            <Text type="secondary" style={{ marginTop: '5px' }}>
              Choose something easy to remember such as your name or pen name.
            </Text>
            <Button 
              onClick={() => handleGenerateReferralCode(customReferralCode)} 
              loading={isGeneratingReferralCode}
              disabled={!customReferralCode}
              style={{ width: '100%', marginTop: '10px' }}
            >
              Use Custom Code
            </Button>
          </>
        )}
      </Space>
    </Modal>
	
  </div>  
);
};

export default Account;
