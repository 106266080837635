//AnalyticsTab.tsx
import React, { useState, useEffect } from 'react';
import { Card, Typography, Select, Spin, Button  } from 'antd';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, Legend } from 'recharts';
import axios from 'axios';

const { Title } = Typography;
const { Option } = Select;

interface AnalyticsTabProps {
  usersSharedState: any;
}

interface UserData {
  userID: string;
  createDate: string;
  stripeProductID: string;
  betaAccess: boolean;
  planName: string;
}

interface BookData {
  bookGuid: string;
  author: string;
  language: string;
  translation_pairs: string[];
  uploadType: string;
  userId: string; 
}

interface TokenUsageData {
  id: string;
  model: string;
  type: string;
  input_tokens: number;
  output_tokens: number;
  timestamp: string;
  bookID: string;
  chapterID: string;  
}

interface ProcessedTokenUsageData {
  timestamp: string;
  input_tokens: number;
  output_tokens: number;
  total_tokens: number;
  bookID: string;
  chapterID: string;
  model: string;
  type: string;
}


interface AdminData {
  users: UserData[];
  books: BookData[];
  token_usage: TokenUsageData[];
}

interface UploadTypeCount {
  [key: string]: number;
}

interface UserGrowthData {
  date: string;
  users: number;
}

interface TranslationPairsData {
  name: string;
  count: number;
}

interface AnalyticsData {
  totalUsers: number;
  averageBooksPerUser: number;
  topUsers: string[];
  topTranslationPairs: { pair: string; count: number }[];
  booksWithTranslationsCount: number;
  totalBooks: number;
  booksWithoutTranslations: number;
  uploadTypeCount: UploadTypeCount;
}

interface AccountProps {
  isAuth: boolean;
  usersSharedState: any;
  setUsersSharedState: any;
  showLoginModal: () => void;
}

interface PlanData {
  planName: string;
  userCount: number;
}

interface BetaAccessData {
  betaAccess: string;
  userCount: number;
}

interface TimeFrameOption {
  key: string;
  text: string;
}

const AnalyticsTab: React.FC<AnalyticsTabProps> = ({ usersSharedState }) => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData>({
    totalUsers: 0,
    averageBooksPerUser: 0,
    topUsers: [],
    topTranslationPairs: [],
    booksWithTranslationsCount: 0,
    totalBooks: 0,
    booksWithoutTranslations: 0,
    uploadTypeCount: {},
  });
  const [userGrowthData, setUserGrowthData] = useState<UserGrowthData[]>([]);
  const [translationPairsData, setTranslationPairsData] = useState<TranslationPairsData[]>([]);
  const [planData, setPlanData] = useState<PlanData[]>([]);
  const [betaAccessData, setBetaAccessData] = useState<BetaAccessData[]>([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('7days');
  const [adminData, setAdminData] = useState<AdminData>({ users: [], books: [], token_usage: [] });
  const [loading, setLoading] = useState<boolean>(true);  
  const [tokenUsageData, setTokenUsageData] = useState<ProcessedTokenUsageData[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>('all');
  const [selectedType, setSelectedType] = useState<string>('all');

  useEffect(() => {
    calculateAnalytics(adminData);
  }, [adminData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`http://localhost:8000/admin-data/${usersSharedState.userID}`);
        const data: AdminData = response.data;
        setAdminData(data);
        calculateAnalytics(data);
		
		//console.log("Token Usage Data:", data.token_usage);
		
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [usersSharedState.userID]);

  useEffect(() => {
    if (adminData.users.length > 0) {
      const growthData = processUserGrowthData(adminData.users);
      setUserGrowthData(growthData);

      const translationData = processTranslationPairsData(analyticsData.topTranslationPairs);
      setTranslationPairsData(translationData);

      const planCounts = processPlanData(adminData.users);
      setPlanData(planCounts);

      const betaCounts = processBetaAccessData(adminData.users);
      setBetaAccessData(betaCounts);
    }
  }, [adminData, analyticsData, selectedTimeFrame]);

const formatNumber = (value: number): string => {
  return value.toLocaleString();
};

  const calculateAnalytics = (data: AdminData) => {
    const cutoffDate = new Date('2024-03-01T00:00:00Z');
    const recentUsers = data.users.filter(user => new Date(user.createDate) >= cutoffDate);

    const totalUsers = recentUsers.length;
    const bookCountPerUser: { [key: string]: number } = {};
    const translationPairsCounter: { [key: string]: number } = {};
    let booksWithNoTranslations = 0;
    const localUploadTypeCount: { [key: string]: number } = {};

    const booksForRecentUsers = data.books.filter(book =>
      recentUsers.some(user => user.userID === book.userId)
    );

    booksForRecentUsers.forEach(book => {
      bookCountPerUser[book.userId] = (bookCountPerUser[book.userId] || 0) + 1;

  if (book.translation_pairs && book.translation_pairs.length > 0) {
    book.translation_pairs.forEach(pair => {
      translationPairsCounter[pair] = (translationPairsCounter[pair] || 0) + 1;
    });
  } else {
    booksWithNoTranslations++;
  }

  localUploadTypeCount[book.uploadType] = (localUploadTypeCount[book.uploadType] || 0) + 1;
});

    const usersWithAtLeastOneBook = Object.values(bookCountPerUser).filter(count => count > 0).length;
    const averageBooksPerUser = usersWithAtLeastOneBook > 0 ? booksForRecentUsers.length / usersWithAtLeastOneBook : 0;

    const topUsers = Object.entries(bookCountPerUser)
      .filter(([_, count]) => count > 0)
      .map(([userID, bookCount]) => ({ userID, bookCount }))
      .sort((a, b) => b.bookCount - a.bookCount)
      .slice(0, 3)
      .map(({ userID, bookCount }) => `${userID} (${bookCount} books)`);

const topTranslationPairs = Object.entries(translationPairsCounter)
  .map(([pair, count]) => ({ pair, count }))
  .sort((a, b) => b.count - a.count)
  .slice(0, 5) as { pair: string; count: number }[];

    setAnalyticsData({
      totalUsers: totalUsers,
      averageBooksPerUser: averageBooksPerUser,
      topUsers: topUsers,
      topTranslationPairs: topTranslationPairs,
      booksWithTranslationsCount: booksForRecentUsers.length - booksWithNoTranslations,
      totalBooks: booksForRecentUsers.length,
      booksWithoutTranslations: booksWithNoTranslations,
      uploadTypeCount: localUploadTypeCount,
    });
  };

const processUserGrowthData = (users: UserData[]): UserGrowthData[] => {
  const endDate = new Date();
  let startDate = new Date();

  switch (selectedTimeFrame) {
    case '7days':
      startDate.setDate(endDate.getDate() - 7);
      break;
    case '1month':
      startDate.setMonth(endDate.getMonth() - 1);
      break;
    case 'all':
      startDate = new Date(Math.min(...users.map(user => new Date(user.createDate).getTime())));
      break;
  }

  let groupedByDate: { [key: string]: number } = {};
  users.forEach(user => {
    const userDate = new Date(user.createDate);
    if (userDate >= startDate && userDate <= endDate) {
      const date = userDate.toLocaleDateString();
      groupedByDate[date] = (groupedByDate[date] || 0) + 1;
    }
  });

  const fullDateRange = [];
  while (startDate <= endDate) {
    const formattedDate = startDate.toLocaleDateString();
    fullDateRange.push({
      date: formattedDate,
      users: groupedByDate[formattedDate] || 0
    });
    startDate.setDate(startDate.getDate() + 1);
  }

  return fullDateRange;
};

  const processTranslationPairsData = (pairs: { pair: string; count: number }[]): TranslationPairsData[] => {
    return pairs.map((pair) => ({ name: pair.pair, count: pair.count }));
  };

  const processPlanData = (users: UserData[]): PlanData[] => {
    const planCounts: { [key: string]: number } = {};
    users.forEach(user => {
      if (user.planName) {
        planCounts[user.planName] = (planCounts[user.planName] || 0) + 1;
      }
    });

    return Object.entries(planCounts).map(([planName, userCount]) => ({
      planName,
      userCount,
    }));
  };

  const processBetaAccessData = (users: UserData[]): BetaAccessData[] => {
    let betaCounts = {
      optedIn: 0,
      optedOut: 0,
    };

    users.forEach(user => {
      if (user.betaAccess) {
        betaCounts.optedIn += 1;
      } else {
        betaCounts.optedOut += 1;
      }
    });

    return [
      { betaAccess: "Opted In", userCount: betaCounts.optedIn },
      { betaAccess: "Opted Out", userCount: betaCounts.optedOut },
    ];
  };


const processTokenUsageData = (data: TokenUsageData[], timeFrame: string, model: string, type: string): ProcessedTokenUsageData[] => {
  const endDate = new Date();
  let startDate = new Date();

  switch (timeFrame) {
    case 'hour':
      startDate.setHours(endDate.getHours() - 1);
      break;
    case 'day':
      startDate.setDate(endDate.getDate() - 1);
      break;
    case 'week':
      startDate.setDate(endDate.getDate() - 7);
      break;
  }

  const groupedData: { [key: string]: ProcessedTokenUsageData } = {};

  data.forEach(item => {
    const timestamp = new Date(item.timestamp);
    if (
      (model === 'all' || item.model === model) &&
      (type === 'all' || item.type === type) &&
      timestamp >= startDate && timestamp <= endDate
    ) {
      let key: string;
      if (timeFrame === 'hour') {
        key = timestamp.toISOString().slice(0, 16); // Group by minute
      } else if (timeFrame === 'day') {
        key = timestamp.toISOString().slice(0, 13); // Group by hour
      } else {
        key = timestamp.toISOString().slice(0, 10); // Group by day
      }

      if (!groupedData[key]) {
        groupedData[key] = {
          timestamp: key,
          input_tokens: 0,
          output_tokens: 0,
          total_tokens: 0,
          bookID: item.bookID,
          chapterID: item.chapterID,
          model: item.model,
          type: item.type
        };
      }

      groupedData[key].input_tokens += item.input_tokens;
      groupedData[key].output_tokens += item.output_tokens;
      groupedData[key].total_tokens += item.input_tokens + item.output_tokens;
    }
  });

  // Fill in missing data points with zeros
  const result: ProcessedTokenUsageData[] = [];
  while (startDate <= endDate) {
    let key: string;
    if (timeFrame === 'hour') {
      key = startDate.toISOString().slice(0, 16);
      startDate.setMinutes(startDate.getMinutes() + 1);
    } else if (timeFrame === 'day') {
      key = startDate.toISOString().slice(0, 13);
      startDate.setHours(startDate.getHours() + 1);
    } else {
      key = startDate.toISOString().slice(0, 10);
      startDate.setDate(startDate.getDate() + 1);
    }

    result.push(groupedData[key] || {
      timestamp: key,
      input_tokens: 0,
      output_tokens: 0,
      total_tokens: 0
    });
  }

  return result;
};

useEffect(() => {
  if (adminData.token_usage.length > 0) {
    const processedData = processTokenUsageData(adminData.token_usage, selectedTimeFrame, selectedModel, selectedType);
    setTokenUsageData(processedData);
  }
}, [adminData, selectedTimeFrame, selectedModel, selectedType]);

const getUniqueModels = (data: TokenUsageData[]): string[] => {
  return ['all', ...Array.from(new Set(data.map(item => item.model)))];
};

const getUniqueTypes = (data: TokenUsageData[]): string[] => {
  return ['all', ...Array.from(new Set(data.map(item => item.type)))];
};


const TokenUsageGraph: React.FC<{ data: ProcessedTokenUsageData[], timeFrame: string }> = ({ data, timeFrame }) => {
  return (
    <LineChart width={600} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis 
        dataKey="timestamp" 
        tickFormatter={(value) => {
          const date = new Date(value);
          if (timeFrame === 'hour') {
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
          } else if (timeFrame === 'day') {
            return date.toLocaleTimeString([], { hour: '2-digit' });
          } else {
            return date.toLocaleDateString();
          }
        }}
      />
      <YAxis tickFormatter={formatNumber} />
      <Tooltip formatter={(value) => formatNumber(value as number)} />
      <Legend />
      <Line type="monotone" dataKey="input_tokens" stroke="#8884d8" name="Input Tokens" />
      <Line type="monotone" dataKey="output_tokens" stroke="#82ca9d" name="Output Tokens" />
      <Line type="monotone" dataKey="total_tokens" stroke="#ffc658" name="Total Tokens" />
    </LineChart>
  );
};

const TokenUsageStats: React.FC<{ data: ProcessedTokenUsageData[], timeFrame: string }> = ({ data, timeFrame }) => {
  const totalTokenUsage = data.reduce((sum, item) => sum + item.total_tokens, 0);
  const totalInputTokens = data.reduce((sum, item) => sum + item.input_tokens, 0);
  const totalOutputTokens = data.reduce((sum, item) => sum + item.output_tokens, 0);
  const averageTokensPerPeriod = totalTokenUsage / data.length;
  const averageInputTokensPerPeriod = totalInputTokens / data.length;
  const averageOutputTokensPerPeriod = totalOutputTokens / data.length;

  const inputTokenPercentage = (totalInputTokens / totalTokenUsage) * 100;
  const outputTokenPercentage = (totalOutputTokens / totalTokenUsage) * 100;

  // Calculate estimated costs
  const inputCost = (totalInputTokens / 1000000) * 3; // $3 per million tokens
  const outputCost = (totalOutputTokens / 1000000) * 15; // $15 per million tokens
  const totalCost = inputCost + outputCost;
  
  // Calculate average cost per period
  const averageCostPerPeriod = totalCost / data.length;  

  let timeFrameText;
  let periodText;
  switch (timeFrame) {
    case 'hour':
      timeFrameText = 'Last Hour';
      periodText = 'Minute';
      break;
    case 'day':
      timeFrameText = 'Last 24 Hours';
      periodText = 'Hour';
      break;
    case 'week':
      timeFrameText = 'Last 7 Days';
      periodText = 'Day';
      break;
    default:
      timeFrameText = 'Selected Period';
      periodText = 'Period';
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Title level={4}>Token Usage Statistics for {timeFrameText}</Title>
      <p>Total Tokens Used: {totalTokenUsage.toLocaleString()}</p>
      <p>Total Input Tokens: {totalInputTokens.toLocaleString()} ({inputTokenPercentage.toFixed(2)}% of total)</p>
      <p>Total Output Tokens: {totalOutputTokens.toLocaleString()} ({outputTokenPercentage.toFixed(2)}% of total)</p>
      <p>Average Tokens per {periodText}: {averageTokensPerPeriod.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
      <p>Average Input Tokens per {periodText}: {averageInputTokensPerPeriod.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
      <p>Average Output Tokens per {periodText}: {averageOutputTokensPerPeriod.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
      <Title level={5}>Estimated Costs</Title>
      <p>Input Cost: ${inputCost.toFixed(2)}</p>
      <p>Output Cost: ${outputCost.toFixed(2)}</p>
      <p>Total Estimated Cost: ${totalCost.toFixed(2)}</p>	  
	  <p>Average Cost per {periodText}: ${averageCostPerPeriod.toFixed(4)}</p>
    </div>
  );
};

const exportToCSV = () => {
  const headers = ['ID', 'Model', 'Type', 'Input Tokens', 'Output Tokens', 'Timestamp', 'Book ID', 'Chapter ID'];
  const csvContent = [
    headers.join(','),
    ...adminData.token_usage.map(row => 
      `${row.id},${row.model},${row.type},${row.input_tokens},${row.output_tokens},${row.timestamp},${row.bookID},${row.chapterID}`
    )
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'raw_token_usage_data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

if (loading) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <Spin size="large" />
      <p style={{ marginTop: '20px' }}>Loading analytics data...</p>
    </div>
  );
}

  return (
    <div>
      <Card title="Analytics Data">
        <p>Total Users (since Mar 1, 2024): {analyticsData.totalUsers}</p>
        <p>Total Books: {analyticsData.totalBooks}</p>
        <p>Average Books Per User: {analyticsData.averageBooksPerUser.toFixed(2)}</p>
        <p>Top Users: {analyticsData.topUsers.join(', ')}</p>
        <p>Top Translation Pairs: {analyticsData.topTranslationPairs.map(pair => `${pair.pair} (${pair.count})`).join(', ')}</p>
        <p>Upload Type Counts: {JSON.stringify(analyticsData.uploadTypeCount)}</p>
        <p>Number of Books with Translations: {analyticsData.booksWithTranslationsCount}</p>
        <p>Number of Books without Translations: {analyticsData.booksWithoutTranslations}</p>
      </Card>

      <Card title="User Growth Over Time">
        <Select defaultValue="7days" style={{ width: 120, marginBottom: 20 }} onChange={value => setSelectedTimeFrame(value)}>
          <Option value="7days">Last 7 Days</Option>
          <Option value="1month">Last Month</Option>
          <Option value="all">All Time</Option>
        </Select>
        <LineChart width={600} height={300} data={userGrowthData}>
          <Line type="monotone" dataKey="users" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </Card>

      <Card title="Translation Pairs">
        <BarChart width={600} height={300} data={translationPairsData}>
          <Bar dataKey="count" fill="#82ca9d" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
        </BarChart>
      </Card>

      <Card title="User Plans Distribution">
        <BarChart width={600} height={300} data={planData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="planName" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="userCount" fill="#8884d8" />
        </BarChart>
      </Card>

      <Card title="Beta Access">
        <BarChart width={600} height={300} data={betaAccessData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="betaAccess" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="userCount" fill="#8884d8" />
        </BarChart>
      </Card>
	  
<Card title="Token Usage">
  <div style={{ display: 'flex', marginBottom: 20 }}>
    <Select 
      defaultValue="week" 
      style={{ width: 120, marginRight: 10 }} 
      onChange={value => setSelectedTimeFrame(value)}
    >
      <Option value="hour">Last Hour</Option>
      <Option value="day">Last Day</Option>
      <Option value="week">Last 7 Days</Option>
    </Select>
    
    
      <Select
        defaultValue="all"
        style={{ width: 300, marginRight: 10 }}
        onChange={value => setSelectedModel(value)}
      >
        {getUniqueModels(adminData.token_usage).map(model => (
          <Option key={model} value={model}>{model}</Option>
        ))}
      </Select>
      
      <Select
        defaultValue="all"
        style={{ width: 200, marginRight: 10 }}
        onChange={value => setSelectedType(value)}
      >
        {getUniqueTypes(adminData.token_usage).map(type => (
          <Option key={type} value={type}>{type}</Option>
        ))}
      </Select>
  
    <Button onClick={exportToCSV} type="primary">
      Export to CSV
    </Button>
  
  </div>
  
  <TokenUsageGraph data={tokenUsageData} timeFrame={selectedTimeFrame} />
  
  <TokenUsageStats data={tokenUsageData} timeFrame={selectedTimeFrame} />
</Card>
	  
    </div>
  );
};

export default AnalyticsTab;