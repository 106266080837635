import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Tabs, Spin, message, Input, Space, Tooltip, Divider } from 'antd';
import { QuestionCircleOutlined, CopyOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const { TabPane } = Tabs;
const { TextArea } = Input;

interface Book {
  title: string;
  author: string;
  genre: string[];
  blurb: string;
}

interface MarketingMaterial {
  type: string;
  content: string;
  chapter?: number;
}

const Marketing: React.FC = () => {
  const { bookId } = useParams<{ bookId?: string }>();
  const [book, setBook] = useState<Book>({ title: '', author: '', genre: [], blurb: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('hooks');
  const [marketingMaterials, setMarketingMaterials] = useState<MarketingMaterial[]>([]);
  const [generatedContent, setGeneratedContent] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    fetchBookDetails();
    fetchMarketingMaterials();
  }, [bookId]);

  const fetchBookDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/marketing/book/${bookId}`);
      setBook(response.data);
    } catch (error) {
      console.error("Error fetching book details:", error);
      message.error("Failed to fetch book details");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMarketingMaterials = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/marketing/materials/${bookId}`);
      setMarketingMaterials(response.data);
    } catch (error) {
      console.error("Error fetching marketing materials:", error);
      message.error("Failed to fetch marketing materials");
    } finally {
      setIsLoading(false);
    }
  };

  const generateContent = async (contentType: string) => {
    setIsGenerating(true);
    try {
      const response = await axios.post('https://your-api-url.com/generate-marketing', {
        bookId,
        contentType,
        userId: auth.currentUser?.uid,
      });
      setGeneratedContent(response.data.content);
    } catch (error) {
      console.error("Error generating content:", error);
      message.error("Failed to generate content");
    } finally {
      setIsGenerating(false);
    }
  };

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
    message.success('Content copied to clipboard');
  };

  return (
    <Spin spinning={isLoading || isGenerating}>
      <div style={{ padding: '20px' }}>
{/* Title and Buttons Row */}
<div style={{ backgroundColor: '#f0f2f5', padding: '0 10px', borderRadius: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  
  {/* Title with reduced margin */}
  <h2 style={{ margin: '10px 0' }}>
    {book.title} - Marketing Materials
  </h2>
{/* Buttons */}
<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <Tooltip title="Fetch hook suggestions for your book to capture readers' attention.">
    <Button 
      onClick={fetchBookDetails}
      style={{ marginRight: '10px', width: 'auto' }}
    >
      Get Hooks
      <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
    </Button>
  </Tooltip>

  <Tooltip title="Generate advertising copy to promote your book effectively.">
    <Button 
      onClick={fetchBookDetails}
      style={{ marginRight: '10px', width: 'auto' }}
    >
      Generate Ad Copy
      <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
    </Button>
  </Tooltip>

  <Tooltip title="Pull excerpt text for TikTok slides to market your book on the platform.">
    <Button 
      onClick={fetchBookDetails}
      style={{ marginRight: '10px', width: 'auto' }}
    >
      Get Tiktok Slides Text
      <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
    </Button>
  </Tooltip>
</div>
</div>
<Divider />

        <Tabs defaultActiveKey="hooks">
          <TabPane tab="Hooks" key="hooks">
            {marketingMaterials.filter(m => m.type === 'hooks').map((hook, index) => (
              <Card 
                key={index} 
                title={`Hook ${index + 1}${hook.chapter ? ` (Chapter ${hook.chapter})` : ''}`}
                extra={<Button icon={<CopyOutlined />} onClick={() => copyToClipboard(hook.content)} />}
                style={{ marginBottom: '10px' }}
              >
                <p>{hook.content}</p>
              </Card>
            ))}
          </TabPane>
          <TabPane tab="Ad Copy" key="adCopy">
            {marketingMaterials.filter(m => m.type === 'adCopy').map((ad, index) => (
              <Card 
                key={index} 
                title={`Ad Copy ${index + 1}`}
                extra={<Button icon={<CopyOutlined />} onClick={() => copyToClipboard(ad.content)} />}
                style={{ marginBottom: '10px' }}
              >
                <p>{ad.content}</p>
              </Card>
            ))}
          </TabPane>
          <TabPane tab="TikTok Slides" key="tiktokSlides">
            {marketingMaterials.filter(m => m.type === 'tiktokSlides').map((slide, index) => (
              <Card 
                key={index} 
                title={`TikTok Slide ${index + 1}${slide.chapter ? ` (Chapter ${slide.chapter})` : ''}`}
                extra={<Button icon={<CopyOutlined />} onClick={() => copyToClipboard(slide.content)} />}
                style={{ marginBottom: '10px' }}
              >
                <p>{slide.content}</p>
              </Card>
            ))}
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
};

export default Marketing;