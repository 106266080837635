//TranslationOptionsModal.tsx

import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Card, Select, Tag } from 'antd';
import { QuestionCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const { TabPane } = Tabs;

interface TranslationOption {
  translatedText: string;
  sourceTrans: string;
  desc: string;
}

interface TranslationOptions {
  titleOptions?: TranslationOption[];
  seriesOptions?: TranslationOption[];
}

interface TranslationOptionsModalProps {
  isVisible: boolean;
  onCancel: () => void;
  translationOptions: TranslationOptions;
  translateMetadata: {
    title: boolean;
    series: boolean;
    blurb: boolean;
  };
  bookId: string;
  bookLanguage: string;
  targetLanguage: string;
  fetchBookDetails: () => void;
}

const auth = getAuth();

const TranslationOptionsModal: React.FC<TranslationOptionsModalProps> = ({
  isVisible,
  onCancel,
  translationOptions,
  translateMetadata,
  bookId,
  bookLanguage,
  targetLanguage,
  fetchBookDetails,
}) => {
  const [activeTabKeyTOM, setActiveTabKeyTOM] = useState<string>('1');
  const [selectedTitleOption, setSelectedTitleOption] = useState<TranslationOption | null>(null);
  const [selectedSeriesOption, setSelectedSeriesOption] = useState<TranslationOption | null>(null);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    if (translateMetadata.title && !translateMetadata.series) {
      setActiveTabKeyTOM('1');
    } else if (!translateMetadata.title && translateMetadata.series) {
      setActiveTabKeyTOM('2');
    }
  }, [translateMetadata.title, translateMetadata.series]);

  const handleCancel = async () => {
    onCancel();
    fetchBookDetails();
  };

  const handleOptionSelect = (option: TranslationOption, type: 'title' | 'series') => {
    if (type === 'title') {
      setSelectedTitleOption(option);
      setIsSaveEnabled((!translateMetadata.title || option !== null) &&
                       (!translateMetadata.series || selectedSeriesOption !== null));
    } else {
      setSelectedSeriesOption(option);
      setIsSaveEnabled((!translateMetadata.title || selectedTitleOption !== null) &&
                       (!translateMetadata.series || option !== null));
    }
  };

  const saveSelectedOptions = async () => {
    try {
      if (selectedTitleOption) {
        await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/save-title-translation/`, {
          userId: auth.currentUser?.uid,
		  bookId: bookId,
          title: selectedTitleOption.translatedText,
          sourceLang: bookLanguage,
          targetLang: targetLanguage
        });
      }

      if (selectedSeriesOption) {
        await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/save-series-translation/`, {
          userId: auth.currentUser?.uid,
		  bookId: bookId,
          series: selectedSeriesOption.translatedText,
          sourceLang: bookLanguage,
          targetLang: targetLanguage
        });
      }

      onCancel();
      fetchBookDetails();
    } catch (error) {
      console.error("Error saving Title or Series Translation:", error);
    }
  };

  return (
    <Modal
      title="Select Translation Options"
      visible={isVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="save" type="primary" onClick={saveSelectedOptions} disabled={!isSaveEnabled}>
          Save
        </Button>
      ]}
      width="80%"
    >
      <Tabs defaultActiveKey="1" activeKey={activeTabKeyTOM} onChange={setActiveTabKeyTOM}>
        {translateMetadata.title && (
          <TabPane tab="Title" key="1">
            {translationOptions.titleOptions?.map((option, index) => (
              <Card
                key={index}
                style={{ marginBottom: 16, border: selectedTitleOption === option ? '2px solid blue' : '' }}
                onClick={() => handleOptionSelect(option, 'title')}
              >
                <p><strong>Translated Title:</strong> {option.translatedText}</p>
                <p><strong>Translation Meaning:</strong> {option.sourceTrans}</p>
                <p><strong>Description:</strong> {option.desc}</p>
              </Card>
            ))}
          </TabPane>
        )}
        {translateMetadata.series && (
          <TabPane tab="Series Name" key="2">
            {translationOptions.seriesOptions?.map((option, index) => (
              <Card
                key={index}
                style={{ marginBottom: 16, border: selectedSeriesOption === option ? '2px solid blue' : '' }}
                onClick={() => handleOptionSelect(option, 'series')}
              >
                <p><strong>Translated Series Name:</strong> {option.translatedText}</p>
                <p><strong>Translation Meaning:</strong> {option.sourceTrans}</p>
                <p><strong>Description:</strong> {option.desc}</p>
              </Card>
            ))}
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

export default TranslationOptionsModal;