import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD-wi-Nftqkh826GUIaeP8SPz3kO9GQ1lo",
  authDomain: "askpoeai.firebaseapp.com",
  projectId: "askpoeai",
  storageBucket: "askpoeai.appspot.com",
  messagingSenderId: "165454637276",
  appId: "1:165454637276:web:4f9aafa486c16ca8f4ec19",
  measurementId: "G-LRZ3113586"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { app, db };