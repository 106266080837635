// NeverTranslateModal.tsx
import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Tag, Tooltip, message, Spin   } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, QuestionCircleOutlined  } from '@ant-design/icons';

interface NeverTranslateModalProps {
  isVisible: boolean;
  onClose: () => void;
  currentLanguagePair: string;
  neverTranslateWords: { [key: string]: string[] };
  setNeverTranslateWords: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  bookGuid: string; // Add this line
}

const NeverTranslateModal: React.FC<NeverTranslateModalProps> = ({
  isVisible,
  onClose,
  currentLanguagePair,
  neverTranslateWords,
  setNeverTranslateWords,
  bookGuid,
}) => {
  const [newWord, setNewWord] = useState('');
  const [editingWord, setEditingWord] = useState<string | null>(null);
  const [editedWord, setEditedWord] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Reset state when modal is opened or closed
  useEffect(() => {
    if (isVisible) {
      setNewWord('');
      setEditingWord(null);
      setEditedWord('');
    }
  }, [isVisible]);

const updateAPI = async (words: string[]): Promise<boolean> => {
  setIsLoading(true);
  try {
    const response = await fetch(`https://askpoeai-nswdzfky7q-uc.a.run.app/update-never-translate/${bookGuid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        language_pair: currentLanguagePair,
        words: words,
      }),
    });
    if (!response.ok) {
      throw new Error('Failed to update API');
    }
    return true;
  } catch (error) {
    console.error('Error updating API:', error);
    message.error('Failed to update. Please try again.');
    return false;
  } finally {
    setIsLoading(false);
  }
};

const isValidInput = (input: string): boolean => {
  // Allow Unicode letters, numbers, spaces, hyphens, and apostrophes
  return /^[\p{L}\p{N}\s\-']+$/u.test(input);
};

const handleAddWord = async () => {
  if (newWord.trim() && isValidInput(newWord.trim())) {
    const updatedWords = [...(neverTranslateWords[currentLanguagePair] || []), newWord.trim()];
    const success = await updateAPI(updatedWords);
    if (success) {
      setNeverTranslateWords(prev => ({
        ...prev,
        [currentLanguagePair]: updatedWords
      }));
      setNewWord('');
    }
  } else {
    message.error('Please enter a valid word or phrase (only letters, numbers, spaces, hyphens, and apostrophes are allowed).');
  }
};

const handleSaveEdit = async () => {
  if (editingWord && editedWord.trim() && isValidInput(editedWord.trim())) {
    const updatedWords = neverTranslateWords[currentLanguagePair].map(w => 
      w === editingWord ? editedWord.trim() : w
    );
    const success = await updateAPI(updatedWords);
    if (success) {
      setNeverTranslateWords(prev => ({
        ...prev,
        [currentLanguagePair]: updatedWords
      }));
      setEditingWord(null);
    }
  } else {
    message.error('Please enter a valid word or phrase (only letters, numbers, spaces, and hyphens are allowed).');
  }
};

  const handleRemoveWord = async (word: string) => {
    const updatedWords = neverTranslateWords[currentLanguagePair].filter(w => w !== word);
    const success = await updateAPI(updatedWords);
    if (success) {
      setNeverTranslateWords(prev => ({
        ...prev,
        [currentLanguagePair]: updatedWords
      }));
    }
  };

  const handleEditWord = (oldWord: string) => {
    setEditingWord(oldWord);
    setEditedWord(oldWord);
  };

const handleClose = () => {
  setNewWord('');
  setEditingWord(null);
  setEditedWord('');
  onClose();
};

  return (
    <Modal
      title={
        <span>
          Text to Never Translate for {currentLanguagePair}
          <Tooltip title="The AI will be instructed to never translate the text entered here">
            <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
          </Tooltip>
        </span>
      }
      visible={isVisible}
      onCancel={handleClose}
      footer={[
        <Button key="close" onClick={handleClose}>
          Close
        </Button>
      ]}
    >
	<Spin spinning={isLoading}>
      <Input
        value={newWord}
        onChange={(e) => setNewWord(e.target.value)}
        onPressEnter={handleAddWord}
        placeholder="Enter a word or phrase to never translate"
      />
      <Button onClick={handleAddWord} style={{ marginTop: '10px' }}>
        Add Word/Phrase
      </Button>
      <div style={{ marginTop: '20px', maxHeight: '300px', overflowY: 'auto' }}>
        <h4>Current Text to Never Translate:</h4>
        {neverTranslateWords[currentLanguagePair]?.map((word, index) => (
          <Tag key={index} style={{ marginBottom: '5px', fontSize: '16px' }}>
            {editingWord === word ? (
              <Input
                value={editedWord}
                onChange={(e) => setEditedWord(e.target.value)}
                onPressEnter={handleSaveEdit}
                style={{ width: '120px' }}
              />
            ) : (
              word
            )}
            <Tooltip title={editingWord === word ? "Save" : "Edit"}>
              {editingWord === word ? (
                <SaveOutlined
                  onClick={handleSaveEdit}
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                />
              ) : (
                <EditOutlined
                  onClick={() => handleEditWord(word)}
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                />
              )}
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                onClick={() => handleRemoveWord(word)}
                style={{ marginLeft: '5px', cursor: 'pointer', color: 'red' }}
              />
            </Tooltip>
          </Tag>
        ))}
      </div>
	  </Spin>
    </Modal>
  );
};

export default NeverTranslateModal;