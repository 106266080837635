// SummarizeOfferModal.tsx
import React, { useState } from 'react';
import { Modal, Checkbox, Button, Tooltip, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Book, Chapter } from './types';


interface SummarizeOfferModalProps {
  isVisible: boolean;
  onClose: (dontShowAgain: boolean) => void;
  onAccept: (dontShowAgain: boolean) => void;
  creditBalance: { credit_balance: number } | null;
  book: Book;
  selectedAIModel: string;
  setSelectedAIModel: (model: string) => void;  
}

const SummarizeOfferModal: React.FC<SummarizeOfferModalProps> = ({
  isVisible,
  onClose,
  onAccept,
  creditBalance,
  book,
  selectedAIModel,
  setSelectedAIModel,  
}) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

const calculateSummarizeCost = (): number => {
  const totalWordCount = book.chapters.reduce((sum, chapter) => sum + chapter.word_count, 0);
  const cost = Math.ceil(totalWordCount * 0.1); // 10% of the total word count, rounded up
  return cost;
};

  const summaryCost = calculateSummarizeCost();
  const userHasEnoughCredits = (creditBalance?.credit_balance ?? 0) >= summaryCost;

  const handleCancel = () => {
    onClose(dontShowAgain);
  };

    return (
    <Modal
      title="Summarize and Make Notes"
      visible={isVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="accept"
          type="primary"
          onClick={() => onAccept(dontShowAgain)}
          disabled={!userHasEnoughCredits}
        >
          Summarize
        </Button>,
      ]}
      width={600}
    >
      <p>
        Would you like ScribeShadow to summarize and make notes of the book? This will help provide better context when translating.<br/><br/>
		It is highly recommended to have your Primary Genre and Tropes filled out before summarizing your book.
      </p>
			
      <div style={{ marginBottom: '10px' }}>
	  {/*
        <label style={{ marginRight: '10px' }}>AI Model:</label>

		<Select
          value={selectedAIModel}
          onChange={(value) => setSelectedAIModel(value)}
          style={{ width: '100%' }}
        >
          <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>
          <Select.Option value="Claude">Claude 3.5</Select.Option>
        </Select>
			*/}
      </div>       
	  
      <div style={{ marginBottom: '0px' }}>
        <label>Credit Cost: </label>
        <span>{summaryCost}</span>
        <Tooltip title="The cost is calculated as 10% of the book's total word count, rounded up, with a minimum of 500 credits.">
          <QuestionCircleOutlined style={{ marginLeft: 8 }} />
        </Tooltip>
      </div>
	  
      <div style={{ marginBottom: '10px' }}>
        <label>Credit Balance: </label>
        <span>{creditBalance?.credit_balance ?? 0}</span>
      </div>
      


      {!userHasEnoughCredits && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          You don't have enough credits for this operation.
        </div>
      )}
{/*
      <p><strong>Note:</strong> Your book is not used to train the AI.</p>

	  <Checkbox
        checked={dontShowAgain}
        onChange={(e) => setDontShowAgain(e.target.checked)}
      >
        Don't show this again for this book
      </Checkbox>
      
      {dontShowAgain && (
        <p>You can initiate the summarization at any time from the edit metadata screen.</p>
      )}
	  */}
    </Modal>
  );
};


export default SummarizeOfferModal;