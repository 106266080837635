// AddLanguageModal.tsx
import React, { useState } from 'react';
import { Modal, Select, Button, message, Spin } from 'antd';
import axios from 'axios';

interface AddLanguageModalProps {
  isVisible: boolean;
  onClose: () => void;
  onLanguageAdded: () => void;
  bookId: string;
  existingLanguages: string[];
  allLanguages: string[];
  sourceLanguage: string;
  betaLanguages: string[]; // Add this prop
}

const AddLanguageModal: React.FC<AddLanguageModalProps> = ({
  isVisible,
  onClose,
  onLanguageAdded,
  bookId,
  existingLanguages,
  allLanguages,
  sourceLanguage,
  betaLanguages,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

const handleAddLanguage = async () => {
  if (!selectedLanguage) {
    message.error('Please select a language');
    return;
  }

  setIsLoading(true);
  try {
    await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/add-language/${bookId}`, 
      JSON.stringify({ language: selectedLanguage }), // Stringify the data
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    message.success('Language added successfully');
    onLanguageAdded();
    onClose();
  } catch (error) {
    console.error('Error adding language:', error);
    message.error('Failed to add language');
  } finally {
    setIsLoading(false);
  }
};

  const availableLanguages = allLanguages.filter(
    (lang) => !existingLanguages.includes(lang) && lang !== sourceLanguage
  );

  return (
    <Modal
      title="Add Language"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="add" type="primary" onClick={handleAddLanguage}>
          Add
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
	  <Select
	  style={{ width: '100%' }}
	  placeholder="Select a language"
	  value={selectedLanguage}
	  onChange={(value) => setSelectedLanguage(value)}
	  >
	  {availableLanguages.map((lang) => (
	  	<Select.Option key={lang} value={lang}>
	  	{lang}
	  	{betaLanguages.includes(lang) && " (BETA)"}
	  	</Select.Option>
	  ))}
	  </Select>
      </Spin>
    </Modal>
  );
};

export default AddLanguageModal;