//AudioBook.tsx

import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Input, Button, Divider, Tabs, Spin, Modal, Select, message, Tooltip, Tag, Space, Form, Alert, Typography  } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, WarningOutlined, CopyOutlined, FileImageOutlined, UploadOutlined, TranslationOutlined, SaveOutlined,     PlusOutlined, PlayCircleOutlined  } from '@ant-design/icons';

import axios from 'axios';
import { getAuth } from "firebase/auth"; // Import getAuth
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Book, Chapter } from './types';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

interface TranslatedChapter {
  chapter_guid: string;
  translated_text: string;
  proofread_text: string;
  proofread_comments: string;
  translated_word_count: number;
  proofread_word_count: number;
  source_language: string;
  target_language: string;
}

interface BlurbTranslations {
  [key: string]: string;
}

interface EditBook {
  title: string;
  author: string;
  series: string;
  booknumber: number;
  genres: string[]; 
  tropes: string[];
  keywords: string[];
}

interface CreditBalance {
  credit_balance: number;
  planID: string;
  temp_Credits: number;
  forever_credits: number;
}

interface Character {
  name: string;
  voice: string;
}

interface Voice {
  name: string;
  languageCodes: string[];
  ssmlGender: string;
  sampleUrl?: string;
}

interface Voice {
  name: string;
  languageCodes: string[];
  ssmlGender: string;
}


const AudioBookPage = () => {
  const { bookId } = useParams<{ bookId?: string }>();
  const [book, setBook] = useState<Book>({ title: '', author: '', series: '', booknumber: 0, coverUrl: '', chapters: [], genres: [] });
  const [editBook, setEditBook] = useState<EditBook>({
  title: '',
  author: '',
  series: '',
  booknumber: 0,
  genres: [],
  tropes: [],
  keywords: []
  }); 
  const [selectedChapter, setSelectedChapter] = useState<Chapter | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [firstEnter, setFirstEnter] = useState(true);
  const [originalOrder, setOriginalOrder] = useState<Record<string, number>>({});
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [sourceLanguage, setSourceLanguage] = useState('English');
  const [targetLanguage, setTargetLanguage] = useState(''); 
  const [isBetaUser, setIsBetaUser] = useState(false);
  const baseLanguages = ["Dutch", "English", "British English", "French", "German", "Italian", "European Portuguese", "Brazilian Portuguese", "Spanish"];
  const betaLanguages = ["Japanese", "Traditional Chinese", "Simplified Chinese", "Russian", "Thai", "Hindi"];
  const languages = isBetaUser ? [...baseLanguages, ...betaLanguages] : baseLanguages;
  const [creditCost, setCreditCost] = useState<number>(0);
  const [editingChapterGuid, setEditingChapterGuid] = useState<string | null>(null);
  const [editedChapterName, setEditedChapterName] = useState<string>('');
  const [blurbActiveTabKey, setBlurbActiveTabKey] = useState<string>('blurb');
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [exportVersion, setExportVersion] = useState('Original');
  const [untranslatedChapters, setUntranslatedChapters] = useState<string[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  const [bookLanguage, setBookLanguage] = useState<string>('');
  const isSelectedChapter = (chapterGuid: string) => {
      return selectedChapter && chapterGuid === selectedChapter.chapter_guid;
  };  
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const [blurb, setBlurb] = useState<string>('');
  const [blurbTranslations, setBlurbTranslations] = useState<{ [language: string]: string }>({});
  const [tropes, setTropes] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]); 
const [uploadType, setUploadType] = useState<string>('');
const [canTranslateTitle, setCanTranslateTitle] = useState(false);
const [canTranslateSeries, setCanTranslateSeries] = useState(false);
const [canTranslateBlurb, setCanTranslateBlurb] = useState(false);
  const [titleTranslations, setTitleTranslations] = useState<{ [key: string]: string }>({});
  const [seriesTranslations, setSeriesTranslations] = useState<{ [key: string]: string }>({});
const [keywordTranslations, setKeywordTranslations] = useState<{ [key: string]: string[] }>({});
const [exportFormat, setExportFormat] = useState('ePub');
const [isTranslatedTabActive, setIsTranslatedTabActive] = useState(false);
  const [creditBalance, setCreditBalance] = useState<CreditBalance | null>(null);
  const [userHasEnoughCredits, setUserHasEnoughCredits] = useState(true);

const hasUntranslatedChapters = untranslatedChapters.length > 0;
const navigate = useNavigate();
const [selectedAIModel, setSelectedAIModel] = useState<string>("Claude");
const [rejectedChapters, setRejectedChapters] = useState<string[]>([]);
const [betaLanguageWarning, setBetaLanguageWarning] = useState(false);
const [genres, setGenres] = useState<string[]>([]);
const commonGenres = ['Fantasy', 'Science Fiction', 'Mystery', 'Thriller', 'Romance', 'Young Adult', 'Children', 'Historical', 'Non-Fiction', 'Horror'];
const [currentLanguagePair, setCurrentLanguagePair] = useState<string>('blurb');
  const [hasChapterBeenProofread, setHasChapterBeenProofread] = useState<boolean>(false);
 
  const [editingTranMetadataTitle, setEditingTranMetadataTitle] = useState(false);
  const [editingTranMetadataSeries, setEditingTranMetadataSeries] = useState(false);
  const [tempTranMetadataTitle, setTempTranMetadataTitle] = useState('');
  const [tempTranMetadataSeries, setTempTranMetadataSeries] = useState('');
  
const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
const [isAddLanguageModalVisible, setIsAddLanguageModalVisible] = useState(false);
 
const [isEditCharactersModalVisible, setIsEditCharactersModalVisible] = useState(false);
const [characters, setCharacters] = useState<Character[]>([]);
const [voices, setVoices] = useState<any[]>([]);
const [filteredVoices, setFilteredVoices] = useState<Voice[]>([]);
const [languageFilter, setLanguageFilter] = useState<string>('');
const [genderFilter, setGenderFilter] = useState<string>('');
 
 const [translateMetadata, setTranslateMetadata] = useState({
    title: false,
    series: false,
    blurb: false,
  });


  const [selectedTab, setSelectedTab] = useState<string>('Original');

  const handleTabChange = (value: string) => {
    setSelectedTab(value);
  };

const fetchVoices = async () => {
  try {
    const response = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/api/google-cloud/voices');
    setVoices(response.data.voices.map((voice: any) => ({
      ...voice,
      sampleUrl: voice.sampleUrl || '' // Assuming the API provides this
    })));
  } catch (error) {
    console.error('Error fetching voices', error);
    message.error('Failed to fetch voices');
  }
};


useEffect(() => {
  const fetchVoices = async () => {
    try {
      const response = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/api/google-cloud/voices');
      setVoices(response.data.voices);
    } catch (error) {
      console.error('Error fetching voices', error);
      message.error('Failed to fetch voices');
    }
  };

  fetchVoices();
}, []);


useEffect(() => {
  const allChaptersProofread = book.chapters.every(chapter => chapter.proofread_chapter);
  //console.log("hasOriginalProofreadComplete: "+hasOriginalProofreadComplete)
}, [book.chapters]);

const fetchCreditBalance = useCallback(async (userId: string) => {
  try {
    const response = await axios.get<CreditBalance>(`https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/?userID=${userId}`);
    setCreditBalance(response.data);
  } catch (error) {
    console.error('Error fetching credit balance', error);
    message.error('Failed to fetch credit balance', 10);
  }
}, []);


useEffect(() => {
  //console.log("useEffect 1");
  //console.time('useEffect 1');
  const wordCount = calculateWordCount();
  setUserHasEnoughCredits(creditBalance ? creditBalance.credit_balance >= wordCount : false);
  //console.timeEnd('useEffect 1');
}, [creditBalance, selectedChapter, targetLanguage, book.chapters]);


const [isChapterTranslated, setIsChapterTranslated] = useState(false);

  useEffect(() => {
    if (selectedChapter) {
      const translatedChaptersKey = `translated_chapters_${bookLanguage}_${targetLanguage}`;
      const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
      
      // Check if the current chapter is translated
      const isCurrentChapterTranslated = translatedChapters.some(
        tChapter => tChapter.chapter_guid === selectedChapter.chapter_guid
      );
      setIsChapterTranslated(isCurrentChapterTranslated);

      // Check if the previous chapter is translated
      const currentChapterOrder = originalOrder[selectedChapter.chapter_guid] || 0;
    } else {
      setIsChapterTranslated(false);
    }
  }, [selectedChapter, book, bookLanguage, targetLanguage, originalOrder]);

  const isChapterProofread = () => {
    return false;
  };  

  useEffect(() => {
	  //console.log("useEffect 3");
	  //console.time('useEffect 3');
  const handleLogout = () => {
    // Reset all states
    setBook({ title: '', author: '', series: '', booknumber: 0, coverUrl: '', chapters: [], genres: [] });
    setEditBook({ title: '', author: '', series: '', booknumber: 0, genres: [], tropes: [], keywords: [] });
    setSelectedChapter(null);
	setBetaLanguageWarning(false);
	setIsBetaUser(false);
    setIsEditing(false);
    setOriginalOrder({});
    setIsLoading(false);
    setIsTitleValid(true);
    setSourceLanguage('English');
    setTargetLanguage('');
    setEditingChapterGuid(null);
    setEditedChapterName('');
    setBlurbActiveTabKey('blurb');
    setIsExportModalVisible(false);
    setExportVersion('Original');
    setExportFormat('ePub');
    setUntranslatedChapters([]);
    setShowWarning(false);
    setBookLanguage('');
    setActiveTabKey('1');
	setGenres([]);
	setTropes([]);
	setKeywords([]);
    setBlurb('');
    setBlurbTranslations({});
    setCanTranslateTitle(false);
    setCanTranslateSeries(false);
    setCanTranslateBlurb(false);
    setTitleTranslations({});
    setSeriesTranslations({});
    setIsTranslatedTabActive(false);
    setCreditBalance(null);
    setUserHasEnoughCredits(true);
    setTranslateMetadata({
      title: false,
      series: false,
      blurb: false,
    });
	
      // Redirect to /manage-books
      navigate('/manage-books');
    };

    if (!auth.currentUser?.uid) {
      handleLogout();
    }
	//console.timeEnd('useEffect 3');
	}, [auth.currentUser?.uid, navigate]);

  
const calculateWordCount = (): number => {
  if (selectedChapter && !isEditing) {
    // If a specific chapter is selected, count the words in that chapter
    return selectedChapter.word_count * (targetLanguage === 'Hindi' ? 2 : 1);
  } else {
    // Count the words in all untranslated chapters
    const translatedChaptersKey = `translated_chapters_${bookLanguage}_${targetLanguage}`;
    const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];

    const wordCount = book.chapters
      .filter(chapter => 
        !translatedChapters.some(tChapter => tChapter.chapter_guid === chapter.chapter_guid))
      .reduce((sum, chapter) => sum + chapter.word_count, 0);

    return wordCount * (targetLanguage === 'Hindi' ? 2 : 1);
  }
};

  useEffect(() => {
	  //console.log("useEffect 4");
	  //console.time('useEffect 4');
    setCanTranslateTitle(!!book.title);
    setCanTranslateSeries(!!book.series);
    setCanTranslateBlurb(!!blurb);
	//console.timeEnd('useEffect 4');
  }, [book.title, book.series, blurb]);

const languageCodeToName: { [key: string]: string } = {
  'cmn-CN': 'Chinese (Simplified)',
  'cmn-TW': 'Chinese (Traditional)',
  'de-DE': 'German',
  'en-AU': 'English (Australian)',
  'en-GB': 'English (British)',
  'en-IN': 'English (Indian)',
  'en-US': 'English (US)',
  'es-ES': 'Spanish (Spain)',
  'es-US': 'Spanish (US)',
  'fr-CA': 'French (Canadian)',
  'fr-FR': 'French (France)',
  'hi-IN': 'Hindi',
  'it-IT': 'Italian',
  'ja-JP': 'Japanese',
  'nl-BE': 'Dutch (Belgian)',
  'nl-NL': 'Dutch (Netherlands)',
  'pt-BR': 'Portuguese (Brazilian)',
  'pt-PT': 'Portuguese (Portugal)',
  'ru-RU': 'Russian',
  'th-TH': 'Thai'
};

const voiceNames: { [key: string]: string } = {
  "cmn-CN-Standard-A": "Mei - Female - Chinese (Mainland)",
  "cmn-CN-Standard-B": "Liang - Male - Chinese (Mainland)",
  "cmn-CN-Standard-C": "Wei - Male - Chinese (Mainland)",
  "cmn-CN-Standard-D": "Ling - Female - Chinese (Mainland)",
  "cmn-CN-Wavenet-A": "Jia - Female - Chinese (Mainland)",
  "cmn-CN-Wavenet-B": "Hao - Male - Chinese (Mainland)",
  "cmn-CN-Wavenet-C": "Yun - Male - Chinese (Mainland)",
  "cmn-CN-Wavenet-D": "Xia - Female - Chinese (Mainland)",
  "cmn-TW-Standard-A": "Shu - Female - Chinese (Taiwan)",
  "cmn-TW-Standard-B": "Cheng - Male - Chinese (Taiwan)",
  "cmn-TW-Standard-C": "Jie - Male - Chinese (Taiwan)",
  "cmn-TW-Wavenet-A": "Hui - Female - Chinese (Taiwan)",
  "cmn-TW-Wavenet-B": "Kai - Male - Chinese (Taiwan)",
  "cmn-TW-Wavenet-C": "Rui - Male - Chinese (Taiwan)",
  "de-DE-Neural2-A": "Lina - Female - German",
  "de-DE-Neural2-B": "Lukas - Male - German",
  "de-DE-Neural2-C": "Johanna - Female - German",
  "de-DE-Neural2-D": "Felix - Male - German",
  "de-DE-Neural2-F": "Amelie - Female - German",
  "de-DE-Polyglot-1": "Max - Male - German",
  "de-DE-Standard-A": "Lena - Female - German",
  "de-DE-Standard-B": "Jonas - Male - German",
  "de-DE-Standard-C": "Hannah - Female - German",
  "de-DE-Standard-D": "Niklas - Male - German",
  "de-DE-Standard-E": "Luis - Male - German",
  "de-DE-Standard-F": "Julia - Female - German",
  "de-DE-Standard-G": "Mila - Female - German",
  "de-DE-Standard-H": "Leon - Male - German",
  "de-DE-Studio-B": "Paul - Male - German",
  "de-DE-Studio-C": "Marie - Female - German",
  "de-DE-Wavenet-A": "Nora - Female - German",
  "de-DE-Wavenet-B": "David - Male - German",
  "de-DE-Wavenet-C": "Emilia - Female - German",
  "de-DE-Wavenet-D": "Elias - Male - German",
  "de-DE-Wavenet-E": "Ben - Male - German",
  "de-DE-Wavenet-F": "Greta - Female - German",
  "de-DE-Wavenet-G": "Lea - Female - German",
  "de-DE-Wavenet-H": "Henry - Male - German",
  "en-AU-Neural2-A": "Olivia - Female - Australian English",
  "en-AU-Neural2-B": "Oliver - Male - Australian English",
  "en-AU-Neural2-C": "Chloe - Female - Australian English",
  "en-AU-Neural2-D": "Liam - Male - Australian English",
  "en-AU-News-E": "Ava - Female - Australian English",
  "en-AU-News-F": "Emily - Female - Australian English",
  "en-AU-News-G": "Jack - Male - Australian English",
  "en-AU-Polyglot-1": "Jacob - Male - Australian English",
  "en-AU-Standard-A": "Charlotte - Female - Australian English",
  "en-AU-Standard-B": "William - Male - Australian English",
  "en-AU-Standard-C": "Amelia - Female - Australian English",
  "en-AU-Standard-D": "Thomas - Male - Australian English",
  "en-AU-Wavenet-A": "Grace - Female - Australian English",
  "en-AU-Wavenet-B": "James - Male - Australian English",
  "en-AU-Wavenet-C": "Sophia - Female - Australian English",
  "en-AU-Wavenet-D": "Lucas - Male - Australian English",
  "en-GB-Neural2-A": "Emily - Female - British English",
  "en-GB-Neural2-B": "Daniel - Male - British English",
  "en-GB-Neural2-C": "Jessica - Female - British English",
  "en-GB-Neural2-D": "Harry - Male - British English",
  "en-GB-Neural2-F": "Isla - Female - British English",
  "en-GB-News-G": "Sophie - Female - British English",
  "en-GB-News-H": "Megan - Female - British English",
  "en-GB-News-I": "Holly - Female - British English",
  "en-GB-News-J": "Samuel - Male - British English",
  "en-GB-News-K": "George - Male - British English",
  "en-GB-News-L": "Charlie - Male - British English",
  "en-GB-News-M": "Matthew - Male - British English",
  "en-GB-Standard-A": "Lauren - Female - British English",
  "en-GB-Standard-B": "Adam - Male - British English",
  "en-GB-Standard-C": "Rachel - Female - British English",
  "en-GB-Standard-D": "Luke - Male - British English",
  "en-GB-Standard-F": "Abigail - Female - British English",
  "en-GB-Studio-B": "Edward - Male - British English",
  "en-GB-Studio-C": "Katie - Female - British English",
  "en-GB-Wavenet-A": "Evie - Female - British English",
  "en-GB-Wavenet-B": "Oscar - Male - British English",
  "en-GB-Wavenet-C": "Poppy - Female - British English",
  "en-GB-Wavenet-D": "Alfie - Male - British English",
  "en-GB-Wavenet-F": "Freya - Female - British English",
  "en-IN-Neural2-A": "Aditi - Female - Indian English",
  "en-IN-Neural2-B": "Arjun - Male - Indian English",
  "en-IN-Neural2-C": "Rohan - Male - Indian English",
  "en-IN-Neural2-D": "Priya - Female - Indian English",
  "en-IN-Standard-A": "Nisha - Female - Indian English",
  "en-IN-Standard-B": "Rahul - Male - Indian English",
  "en-IN-Standard-C": "Karthik - Male - Indian English",
  "en-IN-Standard-D": "Meera - Female - Indian English",
  "en-IN-Standard-E": "Anjali - Female - Indian English",
  "en-IN-Standard-F": "Vikram - Male - Indian English",
  "en-IN-Wavenet-A": "Kavya - Female - Indian English",
  "en-IN-Wavenet-B": "Aditya - Male - Indian English",
  "en-IN-Wavenet-C": "Siddharth - Male - Indian English",
  "en-IN-Wavenet-D": "Ishaan - Male - Indian English",
  "en-IN-Wavenet-E": "Zara - Female - Indian English",
  "en-IN-Wavenet-F": "Dhruv - Male - Indian English",
  "en-US-Casual-K": "Ethan - Male - American English",
  "en-US-Journey-D": "Anthony - Male - American English",
  "en-US-Journey-F": "Ashley - Female - American English",
  "en-US-Journey-O": "Emily - Female - American English",
  "en-US-Neural2-A": "Jacob - Male - American English",
  "en-US-Neural2-C": "Madison - Female - American English",
  "en-US-Neural2-D": "Joshua - Male - American English",
  "en-US-Neural2-E": "Elizabeth - Female - American English",
  "en-US-Neural2-F": "Samantha - Female - American English",
  "en-US-Neural2-G": "Sarah - Female - American English",
  "en-US-Neural2-H": "Nicholas - Male - American English",
  "en-US-Neural2-I": "Joseph - Male - American English",
  "en-US-Neural2-J": "Michael - Male - American English",
  "en-US-News-K": "Brianna - Female - American English",
  "en-US-News-L": "Evelyn - Female - American English",
  "en-US-News-N": "Benjamin - Male - American English",
  "en-US-Polyglot-1": "Mason - Male - American English",
  "en-US-Standard-A": "Michael - Male - American English",
  "en-US-Standard-B": "Christopher - Male - American English",
  "en-US-Standard-C": "Emily - Female - American English",
  "en-US-Standard-D": "Daniel - Male - American English",
  "en-US-Standard-E": "Natalie - Female - American English",
  "en-US-Standard-F": "Anna - Female - American English",
  "en-US-Standard-G": "Abigail - Female - American English",
  "en-US-Standard-H": "Madison - Female - American English",
  "en-US-Standard-I": "Ethan - Male - American English",
  "en-US-Standard-J": "Alexander - Male - American English",
  "en-US-Studio-O": "Zoe - Female - American English",
  "en-US-Studio-Q": "Ryan - Male - American English",
  "en-US-Wavenet-A": "Matthew - Male - American English",
  "en-US-Wavenet-B": "Andrew - Male - American English",
  "en-US-Wavenet-C": "Abigail - Female - American English",
  "en-US-Wavenet-D": "David - Male - American English",
  "en-US-Wavenet-E": "Madison - Female - American English",
  "en-US-Wavenet-F": "Natalie - Female - American English",
  "en-US-Wavenet-G": "Zoe - Female - American English",
  "en-US-Wavenet-H": "Anna - Female - American English",
  "en-US-Wavenet-I": "Ryan - Male - American English",
  "en-US-Wavenet-J": "Joseph - Male - American English",
  "es-ES-Neural2-A": "Lucía - Female - Spanish (Spain)",
  "es-ES-Neural2-B": "Alejandro - Male - Spanish (Spain)",
  "es-ES-Neural2-C": "Sofía - Female - Spanish (Spain)",
  "es-ES-Neural2-D": "Carmen - Female - Spanish (Spain)",
  "es-ES-Neural2-E": "Elena - Female - Spanish (Spain)",
  "es-ES-Neural2-F": "Diego - Male - Spanish (Spain)",
  "es-ES-Polyglot-1": "Pablo - Male - Spanish (Spain)",
  "es-ES-Standard-A": "María - Female - Spanish (Spain)",
  "es-ES-Standard-B": "Javier - Male - Spanish (Spain)",
  "es-ES-Standard-C": "Ana - Female - Spanish (Spain)",
  "es-ES-Standard-D": "Isabel - Female - Spanish (Spain)",
  "es-ES-Standard-E": "Carlos - Male - Spanish (Spain)",
  "es-ES-Standard-F": "Paula - Female - Spanish (Spain)",
  "es-ES-Studio-C": "Sara - Female - Spanish (Spain)",
  "es-ES-Studio-F": "Miguel - Male - Spanish (Spain)",
  "es-ES-Wavenet-B": "Antonio - Male - Spanish (Spain)",
  "es-ES-Wavenet-C": "Marta - Female - Spanish (Spain)",
  "es-ES-Wavenet-D": "Pilar - Female - Spanish (Spain)",
  "es-ES-Wavenet-E": "Andrés - Male - Spanish (Spain)",
  "es-ES-Wavenet-F": "Alba - Female - Spanish (Spain)",
  "es-US-Neural2-A": "Daniela - Female - Spanish (US)",
  "es-US-Neural2-B": "Luis - Male - Spanish (US)",
  "es-US-Neural2-C": "Mateo - Male - Spanish (US)",
  "es-US-News-D": "Jose - Male - Spanish (US)",
  "es-US-News-E": "Emilio - Male - Spanish (US)",
  "es-US-News-F": "Valentina - Female - Spanish (US)",
  "es-US-News-G": "Camila - Female - Spanish (US)",
  "es-US-Polyglot-1": "Juan - Male - Spanish (US)",
  "es-US-Standard-A": "Victoria - Female - Spanish (US)",
  "es-US-Standard-B": "Carlos - Male - Spanish (US)",
  "es-US-Standard-C": "Daniel - Male - Spanish (US)",
  "es-US-Studio-B": "Jorge - Male - Spanish (US)",
  "es-US-Wavenet-A": "Valeria - Female - Spanish (US)",
  "es-US-Wavenet-B": "Gabriel - Male - Spanish (US)",
  "es-US-Wavenet-C": "Sebastián - Male - Spanish (US)",
  "fr-CA-Neural2-A": "Amélie - Female - French (Canada)",
  "fr-CA-Neural2-B": "Simon - Male - French (Canada)",
  "fr-CA-Neural2-C": "Audrey - Female - French (Canada)",
  "fr-CA-Neural2-D": "Félix - Male - French (Canada)",
  "fr-CA-Standard-A": "Émilie - Female - French (Canada)",
  "fr-CA-Standard-B": "Olivier - Male - French (Canada)",
  "fr-CA-Standard-C": "Maude - Female - French (Canada)",
  "fr-CA-Standard-D": "Thomas - Male - French (Canada)",
  "fr-CA-Wavenet-A": "Sophie - Female - French (Canada)",
  "fr-CA-Wavenet-B": "Alexandre - Male - French (Canada)",
  "fr-CA-Wavenet-C": "Catherine - Female - French (Canada)",
  "fr-CA-Wavenet-D": "Maxime - Male - French (Canada)",
  "fr-FR-Neural2-A": "Camille - Female - French (France)",
  "fr-FR-Neural2-B": "Louis - Male - French (France)",
  "fr-FR-Neural2-C": "Manon - Female - French (France)",
  "fr-FR-Neural2-D": "Mathis - Male - French (France)",
  "fr-FR-Neural2-E": "Jade - Female - French (France)",
  "fr-FR-Polyglot-1": "Pierre - Male - French (France)",
  "fr-FR-Standard-A": "Louise - Female - French (France)",
  "fr-FR-Standard-B": "Nathan - Male - French (France)",
  "fr-FR-Standard-C": "Sarah - Female - French (France)",
  "fr-FR-Standard-D": "Gabriel - Male - French (France)",
  "fr-FR-Standard-E": "Alice - Female - French (France)",
  "fr-FR-Standard-F": "Inès - Female - French (France)",
  "fr-FR-Standard-G": "Raphaël - Male - French (France)",
  "fr-FR-Studio-A": "Lucie - Female - French (France)",
  "fr-FR-Studio-D": "Arthur - Male - French (France)",
  "fr-FR-Wavenet-A": "Juliette - Female - French (France)",
  "fr-FR-Wavenet-B": "Théo - Male - French (France)",
  "fr-FR-Wavenet-C": "Mathilde - Female - French (France)",
  "fr-FR-Wavenet-D": "Tom - Male - French (France)",
  "fr-FR-Wavenet-E": "Élise - Female - French (France)",
  "fr-FR-Wavenet-F": "Océane - Female - French (France)",
  "fr-FR-Wavenet-G": "Enzo - Male - French (France)",
  "hi-IN-Neural2-A": "Aditi - Female - Hindi",
  "hi-IN-Neural2-B": "Arjun - Male - Hindi",
  "hi-IN-Neural2-C": "Rahul - Male - Hindi",
  "hi-IN-Neural2-D": "Priya - Female - Hindi",
  "hi-IN-Standard-A": "Neha - Female - Hindi",
  "hi-IN-Standard-B": "Amit - Male - Hindi",
  "hi-IN-Standard-C": "Sanjay - Male - Hindi",
  "hi-IN-Standard-D": "Pooja - Female - Hindi",
  "hi-IN-Standard-E": "Shweta - Female - Hindi",
  "hi-IN-Standard-F": "Rajesh - Male - Hindi",
  "hi-IN-Wavenet-A": "Deepa - Female - Hindi",
  "hi-IN-Wavenet-B": "Suresh - Male - Hindi",
  "hi-IN-Wavenet-C": "Manoj - Male - Hindi",
  "hi-IN-Wavenet-D": "Isha - Female - Hindi",
  "hi-IN-Wavenet-E": "Zara - Female - Hindi",
  "hi-IN-Wavenet-F": "Abhishek - Male - Hindi",
  "it-IT-Neural2-A": "Giulia - Female - Italian",
  "it-IT-Neural2-C": "Luca - Male - Italian",
  "it-IT-Standard-A": "Alessia - Female - Italian",
  "it-IT-Standard-B": "Chiara - Female - Italian",
  "it-IT-Standard-C": "Marco - Male - Italian",
  "it-IT-Standard-D": "Matteo - Male - Italian",
  "it-IT-Wavenet-A": "Sofia - Female - Italian",
  "it-IT-Wavenet-B": "Valentina - Female - Italian",
  "it-IT-Wavenet-C": "Andrea - Male - Italian",
  "it-IT-Wavenet-D": "Francesco - Male - Italian",
  "ja-JP-Neural2-B": "Hana - Female - Japanese",
  "ja-JP-Neural2-C": "Ichiro - Male - Japanese",
  "ja-JP-Neural2-D": "Jiro - Male - Japanese",
  "ja-JP-Standard-A": "Ayumi - Female - Japanese",
  "ja-JP-Standard-B": "Yuki - Female - Japanese",
  "ja-JP-Standard-C": "Kenji - Male - Japanese",
  "ja-JP-Standard-D": "Takashi - Male - Japanese",
  "ja-JP-Wavenet-A": "Emi - Female - Japanese",
  "ja-JP-Wavenet-B": "Sakura - Female - Japanese",
  "ja-JP-Wavenet-C": "Ryu - Male - Japanese",
  "ja-JP-Wavenet-D": "Hiroshi - Male - Japanese",
  "nl-BE-Standard-A": "Emma - Female - Dutch (Belgium)",
  "nl-BE-Standard-B": "Thomas - Male - Dutch (Belgium)",
  "nl-BE-Wavenet-A": "Sophie - Female - Dutch (Belgium)",
  "nl-BE-Wavenet-B": "Lucas - Male - Dutch (Belgium)",
  "nl-NL-Standard-A": "Anna - Female - Dutch (Netherlands)",
  "nl-NL-Standard-B": "Daan - Male - Dutch (Netherlands)",
  "nl-NL-Standard-C": "Luuk - Male - Dutch (Netherlands)",
  "nl-NL-Standard-D": "Lotte - Female - Dutch (Netherlands)",
  "nl-NL-Standard-E": "Eva - Female - Dutch (Netherlands)",
  "nl-NL-Wavenet-A": "Fleur - Female - Dutch (Netherlands)",
  "nl-NL-Wavenet-B": "Sem - Male - Dutch (Netherlands)",
  "nl-NL-Wavenet-C": "Finn - Male - Dutch (Netherlands)",
  "nl-NL-Wavenet-D": "Mila - Female - Dutch (Netherlands)",
  "nl-NL-Wavenet-E": "Sanne - Female - Dutch (Netherlands)",
  "pt-BR-Neural2-A": "Laura - Female - Portuguese (Brazil)",
  "pt-BR-Neural2-B": "Pedro - Male - Portuguese (Brazil)",
  "pt-BR-Neural2-C": "Mariana - Female - Portuguese (Brazil)",
  "pt-BR-Standard-A": "Luiza - Female - Portuguese (Brazil)",
  "pt-BR-Standard-B": "João - Male - Portuguese (Brazil)",
  "pt-BR-Standard-C": "Julia - Female - Portuguese (Brazil)",
  "pt-BR-Standard-D": "Leticia - Female - Portuguese (Brazil)",
  "pt-BR-Standard-E": "Matheus - Male - Portuguese (Brazil)",
  "pt-BR-Wavenet-A": "Gabriela - Female - Portuguese (Brazil)",
  "pt-BR-Wavenet-B": "Bruno - Male - Portuguese (Brazil)",
  "pt-BR-Wavenet-C": "Júlia - Female - Portuguese (Brazil)",
  "pt-BR-Wavenet-D": "Camila - Female - Portuguese (Brazil)",
  "pt-BR-Wavenet-E": "Mateus - Male - Portuguese (Brazil)",
  "pt-PT-Standard-A": "Matilde - Female - Portuguese (Portugal)",
  "pt-PT-Standard-B": "Francisco - Male - Portuguese (Portugal)",
  "pt-PT-Standard-C": "Tiago - Male - Portuguese (Portugal)",
  "pt-PT-Standard-D": "Mariana - Female - Portuguese (Portugal)",
  "pt-PT-Wavenet-A": "Leonor - Female - Portuguese (Portugal)",
  "pt-PT-Wavenet-B": "Diogo - Male - Portuguese (Portugal)",
  "pt-PT-Wavenet-C": "Duarte - Male - Portuguese (Portugal)",
  "pt-PT-Wavenet-D": "Carolina - Female - Portuguese (Portugal)",
  "ru-RU-Standard-A": "Anastasia - Female - Russian",
  "ru-RU-Standard-B": "Ivan - Male - Russian",
  "ru-RU-Standard-C": "Ekaterina - Female - Russian",
  "ru-RU-Standard-D": "Dmitry - Male - Russian",
  "ru-RU-Standard-E": "Olga - Female - Russian",
  "ru-RU-Wavenet-A": "Tatiana - Female - Russian",
  "ru-RU-Wavenet-B": "Alexei - Male - Russian",
  "ru-RU-Wavenet-C": "Elena - Female - Russian",
  "ru-RU-Wavenet-D": "Sergei - Male - Russian",
  "ru-RU-Wavenet-E": "Natalia - Female - Russian",
  "th-TH-Neural2-C": "Malee - Female - Thai",
  "th-TH-Standard-A": "Sukanya - Female - Thai"
};


const fetchCharacters = async () => {
  try {
    const response = await axios.get(`/api/get-characters?bookId=${bookId}`);
    setCharacters(response.data.characters);
  } catch (error) {
    console.error('Error fetching characters', error);
    message.error('Failed to fetch characters');
  }
};

const filterVoices = useCallback(() => {
  let filtered = voices;
  if (languageFilter) {
    filtered = filtered.filter(voice => voice.languageCodes.includes(languageFilter));
  }
  if (genderFilter) {
    filtered = filtered.filter(voice => voice.ssmlGender === genderFilter);
  }
  setFilteredVoices(filtered);
}, [voices, languageFilter, genderFilter]);

useEffect(() => {
  filterVoices();
}, [filterVoices]);

const VoiceSelector = ({ value, onChange, voices }: { value: string, onChange: (value: string) => void, voices: Voice[] }) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  const playVoiceSample = (sampleUrl: string) => {
    if (audio) {
      audio.pause();
    }
    const newAudio = new Audio(sampleUrl);
    newAudio.play();
    setAudio(newAudio);
  };

  return (
    <Select
      showSearch
      placeholder="Select a voice"
      optionFilterProp="children"
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        String(option?.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {voices.map((voice) => (
        <Select.Option key={voice.name} value={voice.name}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{voiceNames[voice.name] || voice.name}</span>
            {voice.sampleUrl && (
              <Button 
                icon={<PlayCircleOutlined />} 
                onClick={(e) => {
                  e.stopPropagation();
                  playVoiceSample(voice.sampleUrl!);
                }}
              />
            )}
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

const handleEditCharacters = () => {
  setIsEditCharactersModalVisible(true);
  fetchCharacters();
};

const handleCloseCharactersModal = () => {
  setIsEditCharactersModalVisible(false);
  setCharacters([]);
};


const handleCharacterNameChange = (index: number, name: string) => {
  const newCharacters = [...characters];
  newCharacters[index].name = name;
  setCharacters(newCharacters);
};

const handleCharacterVoiceChange = (index: number, voice: string) => {
  const newCharacters = [...characters];
  newCharacters[index].voice = voice;
  setCharacters(newCharacters);
};

const handleAddCharacter = () => {
  setCharacters([...characters, { name: '', voice: '' }]);
};

const handleRemoveCharacter = (index: number) => {
  const newCharacters = [...characters];
  newCharacters.splice(index, 1);
  setCharacters(newCharacters);
};

const handleSaveCharacters = async () => {
  try {
    // Save the characters to the backend
    await axios.post('/api/save-characters', { bookId, characters });
    message.success('Characters saved successfully');
    setIsEditCharactersModalVisible(false);
  } catch (error) {
    console.error('Error saving characters', error);
    message.error('Failed to save characters');
  }
};


const renderMetadataTranslationTabContent = (pair: string) => {
    const [sourceLang, targetLang] = pair.split('-').map(lang => lang.replace(/0/g, ' '));
    const titleTranslation = titleTranslations[pair] !== "<Not Translated>" ? titleTranslations[pair] : "";
    const seriesTranslation = seriesTranslations[pair] !== "<Not Translated>" ? seriesTranslations[pair] : "";
    const blurbTranslation = blurbTranslations[pair] !== "<Not Translated>" ? blurbTranslations[pair] : "";
    const keywordsTranslation = keywordTranslations[pair] || [];
	

  const handleTranMetadataEdit = (field: string) => {
    switch(field) {
      case 'title':
        setEditingTranMetadataTitle(true);
        setTempTranMetadataTitle(titleTranslation);
        break;
      case 'series':
        setEditingTranMetadataSeries(true);
        setTempTranMetadataSeries(seriesTranslation);
        break;
    }
	
	
	
  };

const sanitizeInput = (input: string): string => {
  // Define allowed tags
  const allowedTags = ['p', 'strong', 'em', 'u'];
  
  // Create a regex pattern to match allowed tags
  const allowedTagsPattern = allowedTags.join('|');
  const allowedTagsRegex = new RegExp(`</?(?:${allowedTagsPattern})(?:\\s[^>]*)?>`,'gi');
  
  // Replace disallowed tags with their content
  let sanitizedText = input.replace(/<(?!\/?(?:p|strong|em|u)(?:\s[^>]*)?>)[^>]+>/gi, '');
 
  return sanitizedText;
};


  const handleTranMetadataSave = async (field: string, pair: string) => {
  let value = '';
  switch (field) {
    case 'title':
      value = tempTranMetadataTitle || ''; // Use empty string if undefined
      break;
    case 'series':
      value = tempTranMetadataSeries || ''; // Use empty string if undefined
      break;
    default:
      console.error('Unknown field:', field);
      return;
  }

    if (value === '') {
      handleTranMetadataCancel(field);
      return;
    }

    setIsLoading(true);
    try {
      const [sourceLang, targetLang] = pair.split('-');
      const sanitizedValue = sanitizeInput(value);

      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-translation/', {
        userId: auth.currentUser?.uid,
        bookId: bookId,
        field,
        value: sanitizedValue,
        sourceLang,
        targetLang
      });

      if (response.data.status === 'success') {
        switch(field) {
          case 'title':
            setTitleTranslations({...titleTranslations, [pair]: sanitizedValue} as { [key: string]: string });
            setEditingTranMetadataTitle(false);
            break;
          case 'series':
            setSeriesTranslations({...seriesTranslations, [pair]: sanitizedValue} as { [key: string]: string });
            setEditingTranMetadataSeries(false);
            break;
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error(`Error saving ${field} translation:`, error);
      message.error(`Error Saving ${field} translation`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTranMetadataCancel = (field: string) => {
    switch(field) {
      case 'title':
        setEditingTranMetadataTitle(false);
        break;
      case 'series':
        setEditingTranMetadataSeries(false);
        break;
    }
  };

const renderEditableField = (field: string, value: string, editing: boolean, tempValue: string, setTempValue: (value: string) => void) => {
  const handleCopy = async () => {
    try {
      // Create a temporary element to hold the HTML content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = value;
  
      // Get the plain text version
      const plainText = tempElement.textContent || tempElement.innerText;
  
      // Use the Clipboard API to set both HTML and plain text
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([plainText], { type: 'text/plain' }),
          'text/html': new Blob([value], { type: 'text/html' })
        })
      ]);
  
      message.success('Copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
      message.error('Failed to copy');
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['clean'] // remove formatting button
    ]
  };

  const getLabel = (field: string) => {
    switch (field) {
      case 'title':
        return 'Translated Title:';
      case 'series':
        return 'Translated Series Name:';
      case 'blurb':
        return 'Translated Blurb:';
      default:
        return field;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <strong>{getLabel(field)}</strong>
        {!editing && (
          <div style={{ marginLeft: '10px' }}>
            <EditOutlined
              onClick={() => handleTranMetadataEdit(field)}
              style={{ cursor: 'pointer', marginRight: '5px' }}
            />
            <CopyOutlined
              onClick={handleCopy}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
        {editing && (
          <div style={{ marginLeft: '10px' }}>
            <SaveOutlined 
              onClick={() => handleTranMetadataSave(field, pair)}
              style={{ cursor: 'pointer', marginRight: '5px' }}
            />
            <CloseOutlined 
              onClick={() => handleTranMetadataCancel(field)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
      {editing ? (
        <>
          {field === 'blurb' ? (
            <ReactQuill
              value={tempValue}
              onChange={setTempValue}
              modules={modules}
              style={{ marginBottom: '10px', minHeight: '300px' }}
            />
          ) : (
            <Input
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          )}
        </>
      ) : (
        <div>
          {field === 'blurb' ? (
            <div dangerouslySetInnerHTML={{ __html: value || '<Not translated>' }} />
          ) : (
            <Typography.Text>{value || '<Not translated>'}</Typography.Text>
          )}
        </div>
      )}	  
    </div>
  );
};

  return (
    <div>
      <div>
	  {/* <strong>Translated Title:</strong><br /> */}
        {renderEditableField('title', titleTranslation, editingTranMetadataTitle, tempTranMetadataTitle, setTempTranMetadataTitle)}
      </div><br />
      <div>
      {/* <strong>Translated Series Name:</strong><br /> */}
        {renderEditableField('series', seriesTranslation, editingTranMetadataSeries, tempTranMetadataSeries, setTempTranMetadataSeries)}
      </div><br />

        <Divider />
	  
    </div>
  );
};

  // Helper function to get the original chapter text
const getOriginalChapterText = () => {
  if (selectedChapter) {
    if (selectedChapter.proofread_chapter && selectedChapter.proofread_chapter.trim() !== '') {
      return { content: selectedChapter.proofread_chapter, isProofread: true };
    } else {
      return { content: selectedChapter.content, isProofread: false };
    }
  } else {
    return { content: '', isProofread: false };
  }
};

// Helper function to get the translated chapter text and proofread status
const getTranslatedChapterText = (translatedChapter: TranslatedChapter) => {
  if (translatedChapter.proofread_text && translatedChapter.proofread_text.trim() !== '') {
    return { content: translatedChapter.proofread_text, isProofread: true };
  } else {
    return { content: translatedChapter.translated_text, isProofread: false };
  }
};
 
  const renderTranslatedTabContent = (sourceLang: string, targetLang: string) => {
    const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
    const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey];

    if (translatedChapters && selectedChapter) {
      const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === selectedChapter.chapter_guid);
      if (translatedChapter) {
        const { content: translatedContent, isProofread: isTranslatedProofread } = getTranslatedChapterText(translatedChapter);
		const copyToClipboard = () => {
  const dummyElement = document.createElement('div');
  dummyElement.innerHTML = removeHyperlinks(translatedChapter.translated_text);
  
  // Make sure the dummy element is not displayed
  dummyElement.style.position = 'absolute';
  dummyElement.style.left = '-9999px';
  document.body.appendChild(dummyElement);

  const range = document.createRange();
  range.selectNode(dummyElement);

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      message.success('Translated text copied to clipboard');
    } catch (err) {
      message.error('Failed to copy text to clipboard');
    }

    selection.removeAllRanges();
  } else {
    message.error('Failed to access clipboard');
  }

  // Remove the dummy element from the DOM
  document.body.removeChild(dummyElement);
};
      const content = (
        <div style={{ height: '60vh', overflowY: 'auto' }}>

          <div dangerouslySetInnerHTML={{ __html: removeHyperlinks(translatedContent) }} />
          <Button
            onClick={() => deleteTranslation(selectedChapter.chapter_guid, sourceLang, targetLang)}
            type="primary"
            danger
            style={{ marginTop: '10px' }}
          >
            Delete Translation
          </Button>
        </div>
      );

      return {
        content: content,
        isTranslated: true
      };
    }
  }
  return { content: <div>No translation available for this chapter.</div>, isTranslated: false };
};


const fetchBookDetails = () => {
  setIsLoading(true);
  let newRejectedChapters: string[] = [];

  axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`)
    .then(response => {
      const fetchedBook = response.data;
      setBookLanguage(fetchedBook.language || 'English');
	  setBlurb(fetchedBook.blurb);
	  setGenres(fetchedBook.genre || []);
	  setTropes(fetchedBook.tropes || []);
	  setKeywords(fetchedBook.keywords || []);
	  setSourceLanguage(fetchedBook.language);
	  setExportFormat(fetchedBook.uploadType || '');
	  //setShowSummarizeOffer(fetchedBook.showSummaryOffer ?? true);
        
	  const chapters = (fetchedBook.chapters || []).sort((a: Chapter, b: Chapter) => a.order - b.order);
	  const sortedChapters = (fetchedBook.chapters || []).sort((a: Chapter, b: Chapter) => a.order - b.order);
      
	  setBook({ ...fetchedBook, chapters });

	  setEditBook({ 
	  title: fetchedBook.title, 
	  author: fetchedBook.author, 
	  series: fetchedBook.series, 
	  booknumber: fetchedBook.seriesBookNumber, 
	  genres: fetchedBook.genres || [], 
	  tropes: fetchedBook.tropes || [], 
	  keywords: fetchedBook.tropes || [] 
	  });

	  //console.log("editBook.title: "+editBook.title);

	const temp2 = "never_translate_English-Italian"
	//console.log("never translate English-Italian", fetchedBook[temp2]);

      newRejectedChapters = sortedChapters.filter((chapter: Chapter) => {
		if (chapter.rejected_content) {
          //console.log(`Chapter "${chapter.chapter_name}" was rejected for content`);
          return true;
        }
		else {
                //console.log("RJ check: "+chapter.chapter_name+" "+chapter.rejected_content);
		return false;
		}
      }).map((chapter: Chapter) => chapter.chapter_name);

      setRejectedChapters(newRejectedChapters);

        // Extracting translated blurbs, titles, and series
        const extractedBlurbs: BlurbTranslations = {};
        const extractedTitles: { [key: string]: string } = {};
        const extractedSeries: { [key: string]: string } = {};
		const extractedKeywords: { [key: string]: string[] } = {};
        fetchedBook.translation_pairs?.forEach((pair: string) => {
          const [sourceLang, targetLang] = pair.split('-').map(lang => lang.replace(' ', '0')); // Adjust for field names in Firestore
          const blurbKey = `blurb_${sourceLang}_${targetLang}`;
          const titleKey = `title_${sourceLang}_${targetLang}`;
          const seriesKey = `series_${sourceLang}_${targetLang}`;
		  const keywordsKey = `keywords_${sourceLang}_${targetLang}`;
          if (fetchedBook[blurbKey]) extractedBlurbs[pair] = fetchedBook[blurbKey];
          if (fetchedBook[titleKey]) extractedTitles[pair] = fetchedBook[titleKey];
          if (fetchedBook[seriesKey]) extractedSeries[pair] = fetchedBook[seriesKey];
		  if (fetchedBook[keywordsKey]) extractedKeywords[pair] = fetchedBook[keywordsKey];
        });
        setBlurbTranslations(extractedBlurbs);
        setTitleTranslations(extractedTitles);
        setSeriesTranslations(extractedSeries);
		setKeywordTranslations(extractedKeywords);


      const chapterOrder: Record<string, number> = {};
      chapters.forEach((chapter: Chapter, index: number) => {
        chapterOrder[chapter.chapter_guid] = index + 1;
      });
      setOriginalOrder(chapterOrder);
    })
    .catch(error => {
      console.error("Error fetching book details:", error);
    })
    .finally(() => {
	  if(firstEnter) {
	  setIsEditing(true);
	  setFirstEnter(false);
	  }
      setIsLoading(false);
    });
}



  const countWords = (text: string): number => {
    return text.trim().split(/\s+/).length;
  };

const getDefaultLanguage = async () => {
    try {
        const userID = auth.currentUser?.uid; // Replace with actual user ID
        const languageResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get-default-language', {
          params: { userID: userID }});
        if (languageResponse.data && languageResponse.data.defaultLanguage) {
            //console.log("languageResponse.data.defaultLanguage: ", languageResponse.data.defaultLanguage);
			setTargetLanguage(languageResponse.data.defaultLanguage);
        }
    } catch (error) {
        console.error('Error fetching default language:', error);
    }
};

useEffect(() => {
	//console.log("useEffect 10");
	//console.time('useEffect 10');
	fetchBookDetails();
	getDefaultLanguage();
	//console.timeEnd('useEffect 10');
}, [bookId]);

const handleEditToggle = () => {
  setIsEditing(!isEditing);
  setEditBook({ title: book.title, author: book.author, series: book.series, booknumber: book.seriesBookNumber, genres: book.genres || [], tropes: book.tropes || [], keywords: book.tropes || [] });
  setSelectedChapter(null);
  //handleEditToggleWithCallback();
};

  const handleInputChange = (key: keyof typeof editBook, value: string) => {
    setEditBook({ ...editBook, [key]: value });
	setHasUnsavedChanges(true);
    // If the key is 'title', then also update the isTitleValid state
    if (key === 'title') {
      setIsTitleValid(!!value.trim()); // title is valid if it's not empty or just whitespace
    }
  };

const handleSave = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  if (!isTitleValid) {
    alert("The title is required."); // Provide feedback to the user
    return;
  }

  setIsLoading(true);

  try {
    await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-book/', { 
		bookID: bookId,
		userID: auth.currentUser.uid,
		title: editBook.title,
		author: editBook.author,
		series: editBook.series,
		booknumber: editBook.booknumber,
		language: bookLanguage,
		genre: genres,
		tropes: tropes,
		keywords: keywords,
    });
    setBook({ ...book, ...editBook, genres: genres });
    setIsEditing(false);
	
	//savetheblurb
	if (blurb) {
		handleBlurbSave();
	}
	
	//refresh after update
    const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
    let updatedBook: Book = updatedBookResponse.data;
    updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
    setBook(updatedBook);	
  } catch (error) {
    console.error("Error saving book details:", error);
  }
  setHasUnsavedChanges(false);
  setIsLoading(false);
};

  const deleteTranslation = async (chapterGuid: string, sourceLang: string, targetLang: string) => {
    if (!auth.currentUser) {
      console.error("No user logged in");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://askpoeai-nswdzfky7q-uc.a.run.app/delete-translated-chapter/${bookId}/${chapterGuid}`, 
        {
          user_id: auth.currentUser.uid,
          source_language: sourceLang,
          target_language: targetLang
        }
      );

    if (response.data.status === "error") {
      throw new Error(response.data.message);
    }
	
      // Refresh the book data to reflect the deletion
      const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
      let updatedBook: Book = updatedBookResponse.data;
      updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
      setBook(updatedBook);
      message.success("Translation deleted successfully.");
    } catch (error) {
      console.error("Error deleting translation:", error);
      message.error("Error deleting translation",10);
    } finally {
      setIsLoading(false);
    }
  };


  const selectChapter = (chapter: Chapter) => {
    setSelectedChapter(chapter);
    // Close the editing screen when a chapter is selected
    setIsEditing(false);
  };

const updateUntranslatedChaptersAndWordCount = (newTargetLanguage: string) => {
  //console.log("updateUntranslatedChaptersAndWordCount");
  if (newTargetLanguage === 'Original') {
    // If the version is 'Original', do not show any warning and reset untranslated chapters list
    setUntranslatedChapters([]);
    setShowWarning(false);
    return; // Early return to skip the rest of the function
  }  
  const translatedChaptersKey = `translated_chapters_${bookLanguage}_${newTargetLanguage}`;
  const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
  const untranslated = book.chapters.filter(chapter =>
    !translatedChapters.some(tChapter => tChapter.chapter_guid === chapter.chapter_guid)
  ).map(chapter => chapter.chapter_name);

  setUntranslatedChapters(untranslated);
  setShowWarning(untranslated.length > 0);
  const newWordCount = calculateWordCount();
  setUserHasEnoughCredits(creditBalance !== null && creditBalance.credit_balance >= newWordCount);
};

useEffect(() => {
	//console.log("useEffect 11");
	//console.time('useEffect 11');
  if (!selectedChapter) {
    updateUntranslatedChaptersAndWordCount(targetLanguage);
  }
  //console.timeEnd('useEffect 11');
}, [book, targetLanguage]);


const startEditingChapter = (chapter: Chapter) => {
  setEditingChapterGuid(chapter.chapter_guid);
  setEditedChapterName(chapter.chapter_name);
};

const saveChapterName = async (chapterGuid: string) => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  setIsLoading(true);

  try {
    const response = await axios.post(
      `https://askpoeai-nswdzfky7q-uc.a.run.app/update-chapter-name/${bookId}/${chapterGuid}`,
      {
        user_id: auth.currentUser.uid,
        new_name: editedChapterName
      }
    );

    if (response.data.status === "success") {
      // Update local state
      const updatedChapters = book.chapters.map(chapter => {
        if (chapter.chapter_guid === chapterGuid) {
          return { ...chapter, chapter_name: editedChapterName };
        }
        return chapter;
      });
      setBook({ ...book, chapters: updatedChapters });
      message.success("Chapter name updated successfully");
    } else {
      message.error("Error updating chapter name",10);
    }
  } catch (error) {
    console.error("Error updating chapter name:", error);
    message.error("Error updating chapter name",10);
  }

  // Reset editing state
  setEditingChapterGuid(null);
  setEditedChapterName('');
  setIsLoading(false);
};


const cancelEdit = () => {
  setEditingChapterGuid(null);
  setEditedChapterName('');
};



const handleExport = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  setIsLoading(true);
  //console.log(`Exporting book in ${exportVersion} version and ${exportFormat} format`);
  toggleExportModal();

  try {
    const response = await axios.post(
      `https://askpoeai-nswdzfky7q-uc.a.run.app/export-uploaded-book/${bookId}`, 
      {
        user_id: auth.currentUser.uid,
        version: exportVersion,
        format: exportFormat,
        title: book.title // Include the title of the book
      },
      { responseType: 'blob' } // Important: Set responseType to 'blob' for binary data
    );

    // Create a Blob from the PDF Stream
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    // Generate the filename based on the book's title and format
    const sanitizedTitle = book.title.replace(/\s+/g, '_').replace(/[^\w-]/g, ''); // Replace spaces with underscores and remove non-alphanumeric characters
    const fileExtension = exportFormat === 'Word' ? 'docx' : exportFormat.toLowerCase();
    const filename = `${sanitizedTitle}_${exportVersion}.${fileExtension}`;

    // Create a link and trigger a download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // Use the generated filename
    document.body.appendChild(link);
    link.click();

    message.success("Book exported successfully");
  } catch (error) {
    console.error("Error exporting book:", error);
    message.error("Error exporting book",10);
  } finally {
    setIsLoading(false);
  }
};

const deleteChapter = async (chapterGuid: string) => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  const userId = auth.currentUser.uid; // Assign the user ID to a variable

  const deleteWarning = exportFormat === 'ePub' ?
    (<>
      Deleted chapters will not be translated and will remain in the exported ePub in their original language.<br /><br />
      This action cannot be undone.
    </>) : 
    (<>
      Deleted chapters will not be translated.<br /><br />
      This action cannot be undone.
    </>);

  Modal.confirm({
    title: 'Are you sure you want to delete this chapter?',
    content: deleteWarning,
    onOk: async () => {
      setIsLoading(true);

      try {
		const response = await axios.delete(`https://askpoeai-nswdzfky7q-uc.a.run.app/delete-chapter/${bookId}/${chapterGuid}?user_id=${userId}`);
        // Update the local state to reflect the deletion
        const updatedChapters = book.chapters.filter(chapter => chapter.chapter_guid !== chapterGuid);
        setBook({ ...book, chapters: updatedChapters });
        message.success("Chapter deleted successfully.");
      } catch (error) {
        console.error("Error deleting chapter:", error);
        message.error("Error deleting chapter",10);
      } finally {
        setIsLoading(false);
      }
    },
  });
};


const handleBlurbSave = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  setIsLoading(true);

  try {
    await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-book-blurb/', { 
		bookID: bookId,
		userID: auth.currentUser.uid,
		blurb: blurb
    });
    setIsEditing(false);
	//refresh after update
    const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
    let updatedBook: Book = updatedBookResponse.data;
    updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
    setBook(updatedBook);	
  } catch (error) {
    console.error("Error saving book details:", error);
  }
  setIsLoading(false);
};


const toggleExportModal = () => {

};


function removeHyperlinks(htmlContent: string): string {
  const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
  doc.querySelectorAll('a').forEach(link => {
    link.removeAttribute('href');
    link.style.pointerEvents = 'none';
    link.style.color = 'inherit';
    link.style.textDecoration = 'none';
  });
  return doc.body.innerHTML;
}

const handleExportBookClick = () => {
if(!isEditing){
handleEditToggle();
}
toggleExportModal();
}

  useEffect(() => {
	  //console.log("useEffect 12");
	  //console.time('useEffect 12');
    // Check if the user is part of the beta program
    const userId = auth.currentUser?.uid; 
	const checkBetaStatus = async () => {
      if (userId) {
        try {
          const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/check-beta-status/${userId}`);
          setIsBetaUser(response.data.isBetaUser);
        } catch (error) {
          console.error('Error checking beta status:', error);
        }
      }
    };
	
    checkBetaStatus();
	//console.timeEnd('useEffect 12');
  }, [auth.currentUser?.uid]);	
 
const getCreditCost = () => {
    //console.log("getCreditCost");
    const wordCount = calculateWordCount();
    let totalCost;

    if (selectedAIModel === 'DeepSeek') {
        totalCost = Math.ceil(wordCount * 0.5);
    } else {
        totalCost = wordCount;
    }

    return Math.ceil(totalCost);
};

  const formatLangDisplayText = (pair: string) => {
    const [sourceLang, targetLang] = pair.split('-');
    return `${sourceLang} to ${targetLang}`;
  };

  if (!bookId) {
    return <div>No book ID provided</div>;
  }

  const handleAddLanguage = () => {
    setIsAddLanguageModalVisible(true);
  };
  
  const handleGenerateAudio = () => {
	  
  };

return (
  <Spin spinning={isLoading}>
    <div style={{ padding: '20px' }}>

{/* Title and Buttons Row */}
<div style={{ backgroundColor: '#f0f2f5', padding: '0 10px', borderRadius: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  
  {/* Title with reduced margin */}
  <h2 style={{ margin: '10px 0' }}>
    {book.title}
  </h2>

{/* Buttons */}
<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <Tooltip title="Edit the metadata (Title, Author, Series, Blurb, Language, Genre) of the book.">
    <Button 
      onClick={handleEditToggle}
      style={{ marginRight: '10px', width: 'auto' }}
      disabled={isEditing}
    >
      Edit Metadata
      <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
    </Button>
  </Tooltip>

<Tooltip title="Edit the characters and assign voices.">
  <Button 
    onClick={handleEditCharacters}
    style={{ marginRight: '10px', width: 'auto' }}
  >
    Edit Characters
    <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
  </Button>
</Tooltip>

  <Tooltip title="Export the audiobook.">
    <Button 
      onClick={handleExportBookClick}
      style={{ width: 'auto' }}
    >
      Export Audiobook
      <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
    </Button>
  </Tooltip>
</div>
</div>
<Divider />
      {/* Main Content Area */}
      <div style={{ display: 'flex' }}>

        {/* Left Panel */}
        <div style={{ flex: 1, marginRight: '5px', maxWidth: '250px' }}>
          <div className="droppableArea">
          <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}><center>
            Chapter List
            <Tooltip title="This list contains the chapters of your novel for organizational purposes. Chapter names do not affect the export or translation process.">
              <QuestionCircleOutlined style={{ color: '#1677ff', marginLeft: 8 }} />
            </Tooltip></center>
          </div>
		  {/*
		  <center>		  
            <Button 
              disabled={!selectedChapter} 
              onClick={toggleTranslateModal} 
              style={{ marginBottom: '10px', width: '80%', height: '32px' }}
              icon={<TranslationOutlined />}
            >
              Translate Chapter
            </Button>	

            <Button 
              disabled={!selectedChapter} 
              onClick={toggleProofreadModal} 
              style={{ marginBottom: '10px', width: '80%', height: '32px' }}
              icon={<CheckOutlined />}
            >
              Proofread Chapter
            </Button>			
		  </center> */}
		<div style={{ height: '73vh', overflowY: 'auto' }}>
          {book.chapters.map((chapter, index) => (
            <div 
              key={chapter.chapter_guid}
              className={`chapterItem ${isSelectedChapter(chapter.chapter_guid) ? 'selectedChapter' : ''}`}
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        padding: '4px', 
        margin: '4px 0', 
        borderRadius: '3px', 
        cursor: 'pointer', 
        background: isSelectedChapter(chapter.chapter_guid) ? '#f0f0f0' : '',
        fontSize: '0.85em'
      }}
              onClick={() => selectChapter(chapter)}
            >
              <div style={{ flexGrow: 1, cursor: 'pointer' }}>
                {editingChapterGuid === chapter.chapter_guid ? (
                  <Input
                    value={editedChapterName}
                    onChange={(e) => setEditedChapterName(e.target.value)}
                    style={{ flexGrow: 1, marginRight: '4px', fontSize: '0.5em' }}
                  />
                ) : (
                  chapter.chapter_name
                )}
              </div>

            </div>
          ))}
        </div>
		</div>
      </div>
	  
      {/* Right Panel */}
<div style={{ flex: 5, backgroundColor: '#f0f2f5', padding: '20px', borderRadius: '10px' }}>
  <Card style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', height: '73vh', overflow: 'auto'  }}>
    <div style={{ height: '95%', overflowY: 'auto' }}>
	{isEditing ? (
      <div style={{ display: 'flex', width: '100%' }}>
        {/* Cover and Buttons */}
        <div style={{ marginRight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {book.coverUrl ? (
            <img src={book.coverUrl} alt="Cover" style={{ width: '180px', height: '286px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} />
          ) : (
            <div style={{ width: '180px', height: '286px', backgroundColor: '#e0e0e0', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FileImageOutlined style={{ fontSize: '48px', color: '#999' }} />
            </div>
          )}
          <Space direction="vertical" size="small" style={{ marginTop: '10px', width: '100%' }}>

          </Space>
        </div>

        {/* Metadata Fields */}
			<div style={{ flex: 1 }}>
			<Tabs
			defaultActiveKey="original"
			onChange={(key) => {
				setBlurbActiveTabKey(key);
				setCurrentLanguagePair(key);
				setIsTranslatedTabActive(key !== 'original');
			}}
			tabBarExtraContent={
				<Tooltip title="Add a new metadata language tab">
				<Button
					icon={<PlusOutlined />}
					onClick={handleAddLanguage}
					style={{ marginLeft: '10px' }}
				/>
				</Tooltip>
			}
			>
            <TabPane tab="Original" key="original">
			{hasUnsavedChanges && (
				<Alert
				message="You have unsaved changes in your metadata."
				type="warning"
				showIcon
				style={{ marginBottom: '10px' }}
				/>
			)}			
              <Form layout="vertical">
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                        <Form.Item label={<strong>Title</strong>} required>
                    <Input
                      value={editBook.title}
                      onChange={(e) => handleInputChange('title', e.target.value)}
                      placeholder="Enter book title"
                    />
                  </Form.Item>
                  <Form.Item label={<strong>Author</strong>}>
                    <Input
                      value={editBook.author}
                      onChange={(e) => handleInputChange('author', e.target.value)}
                      placeholder="Enter author name"
                    />
                  </Form.Item>
                  <Form.Item label={<strong>Series</strong>}>
                    <Input
                      value={editBook.series}
                      onChange={(e) => handleInputChange('series', e.target.value)}
                      placeholder="Enter series name"
                    />
                  </Form.Item>
                  <Form.Item label={<strong>Series Book Number</strong>}>
                    <Input
                      value={editBook.booknumber}
                      onChange={(e) => handleInputChange('booknumber', e.target.value)}
                      placeholder="Enter book number in series"
                    />
                  </Form.Item>
                  <Form.Item label={<strong>Language</strong>}>
                    <Select
                      value={bookLanguage}
					onChange={(value) => {
					setBookLanguage(value);
					setHasUnsavedChanges(true);
					}}
                    >
                      {languages.map(lang => (
                        <Select.Option key={lang} value={lang}>{lang}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item 
                    label={
                      <Space>
                        <strong>Primary Genre</strong>
                        <Tooltip title="Select or type in the primary genre of your book. ScribeShadow uses this to help determine tone when translating.">
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Space>
                    }
                  >
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="Type or select a primary genre"
                      value={genres}
					  onChange={(selectedGenres) => {
					  if (selectedGenres.length > 1) {
					 	 message.error('You can only select one primary genre');
					 	 setGenres(selectedGenres.slice(0, 1));
					  } else {
					 	 setGenres(selectedGenres);
					  }
					  setHasUnsavedChanges(true);
					  }}
                      dropdownRender={menu => (
                        <>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div style={{ 
                            display: 'grid', 
                            gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
                            gap: '10px',
                            padding: 8 
                          }}>
                            {commonGenres.map(genre => (
                              <Tag
                                key={genre}
                                style={{ cursor: 'pointer', textAlign: 'center' }}
                                onClick={() => {
                                  if (genres.length === 0) {
                                    setGenres([genre]);
                                  } else {
                                    message.error('You can only select one primary genre');
                                  }
                                }}
                              >
                                {genre}
                              </Tag>
                            ))}
                          </div>
                        </>
                      )}
                    >
                      {genres.map(genre => (
                        <Select.Option key={genre} value={genre}>{genre}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item 
                  label={
                    <Space>
                      <strong>Tropes</strong>
                      <Tooltip title="Enter one or more tropes related to the book. ScribeShadow will use them when generating keyword suggestions.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                >
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Type or select tropes"
                    value={tropes}
					onChange={(value) => {
					setTropes(value);
					setHasUnsavedChanges(true);
					}}
                  >
                    {tropes.map(trope => (
                      <Select.Option key={trope} value={trope}>{trope}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item 
                  label={
                    <Space>
                      <strong>Keywords</strong>
                      <Tooltip title="Enter one or more keywords related to the book, such as those used with Amazon. ScribeShadow will use them when generating keyword suggestions.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                >
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Type or select keywords"
                    value={keywords}
					onChange={(value) => {
					setKeywords(value);
					setHasUnsavedChanges(true);
					}}
                  >
                    {keywords.map(keyword => (
                      <Select.Option key={keyword} value={keyword}>{keyword}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
				<Form.Item
				label={
					<Space>
					<strong>Blurb</strong>
					</Space>
				}
				>
				<ReactQuill
					value={blurb}
					onChange={(value) => {
					setBlurb(value);
					setHasUnsavedChanges(true);
					}}
					modules={{
					toolbar: [
						['bold', 'italic', 'underline'],
						['clean']
					]
					}}
					placeholder="Enter book blurb"
				/>
				</Form.Item>
              </Form>
              {!isTitleValid && <Alert message="Title is required." type="error" showIcon style={{ marginTop: '10px' }} />}
            </TabPane>
            {!selectedChapter && book.translation_pairs && book.translation_pairs.map(pair => (
              <TabPane tab={`${pair}`} key={pair}>
                {renderMetadataTranslationTabContent(pair)}
              </TabPane>
            ))}
          </Tabs>
          <Space style={{ marginTop: '10px' }}>
            {!isTranslatedTabActive && (
              <Button 
                type="primary"
                onClick={handleSave}
                disabled={!isTitleValid}
                icon={<SaveOutlined />}
              >
                Save Metadata
              </Button>
            )}         
  			
          </Space>
        </div>
      </div>
          ) : selectedChapter ? (
    <>
	{/* Individual chapters */}
      {rejectedChapters.includes(selectedChapter.chapter_name) && (
        <div style={{ marginBottom: '10px' }}>
          <Tooltip title="This chapter contains content that may be rejected during translation attempts. It may take several attempts or switching AI Models to successfully translate this content.">
            <span style={{ color: 'red' }}>
              <WarningOutlined /> Content Warning
            </span>
          </Tooltip>
        </div>
      )}
<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
  <Select value={selectedTab} onChange={handleTabChange} style={{ width: '250px' }}>
    <Option value="Original">Original</Option>
    {book.translation_pairs && book.translation_pairs.map(pair => (
      <Option key={pair} value={pair}>{formatLangDisplayText(pair)}</Option>
    ))}
  </Select>
  <div>
    <Button 
      disabled={!selectedChapter} 
      onClick={handleGenerateAudio} 
      style={{ marginRight: '10px', height: '32px' }}
      icon={<TranslationOutlined />}
    >
      Generate Audio
    </Button>	
  </div>
</div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Tabs defaultActiveKey="1" style={{ flex: 1 }}>
        {selectedTab === 'Original' ? (
          <>
            <TabPane tab="Original" key="1">
              {(() => {
                const { content: originalContent, isProofread: isOriginalProofread } = getOriginalChapterText();
                return (
                  <div style={{ height: '55vh', overflowY: 'auto' }}>

                    <div dangerouslySetInnerHTML={{ __html: removeHyperlinks(originalContent) }} />
                  </div>
                );
              })()}
            </TabPane>
          </>
        ) : (
          <>
            {(() => {
              const [sourceLang, targetLang] = selectedTab.split('-');
              const { content, isTranslated } = renderTranslatedTabContent(sourceLang, targetLang);
              return (
                <TabPane
                  tab={
                    <>
                      {`${formatLangDisplayText(selectedTab)}`}
                      {!isTranslated && (
                        <Tooltip title={`This chapter is not yet translated from ${sourceLang} to ${targetLang}.`}>
                          <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
                        </Tooltip>
                      )}
                    </>
                  }
                  key="1"
                >
                  {content}
                </TabPane>
              );
            })()}
          </>
        )}
      </Tabs>
    </div>
  </>
) : (
  <div>No chapter selected</div>
)}
		  </div>
        </Card>

      </div>
	  
	  
    </div>
	</div>
<Modal
  title="Edit Characters"
  visible={isEditCharactersModalVisible}
  onCancel={handleCloseCharactersModal}
  footer={[
    <Button key="cancel" onClick={() => setIsEditCharactersModalVisible(false)}>
      Cancel
    </Button>,
    <Button key="save" type="primary" onClick={handleSaveCharacters}>
      Save
    </Button>,
  ]}
  width={800} // Increased width
>
  <Form layout="vertical">
    <div style={{ display: 'flex', marginBottom: '20px' }}>
      <Form.Item label="Language" style={{ flex: 1, marginRight: '10px' }}>
        <Select
          value={languageFilter}
          onChange={setLanguageFilter}
          allowClear
          style={{ width: '100%' }}
        >
          {Array.from(new Set(voices.flatMap(v => v.languageCodes))).map(lang => (
            <Select.Option key={lang} value={lang}>{languageCodeToName[lang] || lang}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Gender" style={{ flex: 1 }}>
        <Select
          value={genderFilter}
          onChange={setGenderFilter}
          allowClear
          style={{ width: '100%' }}
        >
          <Select.Option value="MALE">Male</Select.Option>
          <Select.Option value="FEMALE">Female</Select.Option>
          <Select.Option value="NEUTRAL">Neutral</Select.Option>
        </Select>
      </Form.Item>
    </div>

    {characters.map((character, index) => (
      <Card key={index} style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item label="Character Name" style={{ flex: 1, marginRight: '20px' }}>
            <Input
              value={character.name}
              onChange={(e) => handleCharacterNameChange(index, e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Voice" style={{ flex: 2 }}>
            <VoiceSelector
              value={character.voice}
              onChange={(value) => handleCharacterVoiceChange(index, value)}
              voices={filteredVoices}
            />
          </Form.Item>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleRemoveCharacter(index)}
            style={{ marginLeft: '10px' }}
          />
        </div>
      </Card>
    ))}
    
    <Button 
      type="dashed" 
      onClick={handleAddCharacter} 
      style={{ width: '100%', height: '40px' }}
      icon={<PlusOutlined />}
    >
      Add Character
    </Button>
  </Form>
</Modal>


  </Spin>
);

};

export default AudioBookPage;

